<template>
  <div v-if="tableData.length">
    <v-data-table
      ref="commandeTable"
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1"
      @page-count="pageCount = $event"
    >
      <!-- id-->
      <template
        slot="item.id"
        slot-scope="props"
      >
        <span class="font-weight-bold">{{ props.item.id }}</span>
      </template>

      <!-- Statut-->
      <template
        slot="item.statut"
        slot-scope="props"
      >
        <!-- Îcone du statut courant-->
        <v-icon
          large
          :color="getStatusIconColor(props.item.statut)"
        >
          {{ getStatusIcon(props.item.statut) }}
        </v-icon>
        {{ $t(`order.operation.status.${props.item.statut}`) }}

        <!-- Pour le développement, on affiche le status-->
        <span
          v-if="env === 'development'"
          class="red--text"
        >Statut {{ props.item.statut }}</span>
      </template>

      <!-- Étape-->
      <template
        slot="item.action"
        slot-scope="props"
      >
        <template v-if="orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].operation.action">
          <!-- Bouton-->
          <v-btn
            v-if="orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].operation.action.bouton"
            class="mb-2"
            elevation="2"
            small
            :color="orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].operation.action.couleur"
            @click="handleFunctionCall(orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].operation.action.fonction.nom, props.item, orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].operation.action.fonction.messageNo)"
          >
            <v-icon
              v-if="orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].operation.action.icon"
              left
              dark
              :color="orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].operation.action.icon.couleur"
            >
              {{ orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].operation.action.icon.nom }}
            </v-icon>
            {{ $t(orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].operation.action.nom) }}
          </v-btn>

          <!-- Pas de bouton-->
          <template v-else>
            <v-icon
              v-if="orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].operation.action.icon"
              large
              left
              dark
              :color="orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].operation.action.icon.couleur"
            >
              {{ orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].operation.action.icon.nom }}
            </v-icon>
            {{ $t(orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].operation.action.nom) }}
          </template>

          <!-- Bouton pour l'approbation d'un ubr pour l'UQAM-->
          <div v-if="$company === 'uqam' && props.item.statut === 2 && props.item.etape === 3">
            <a
              href=""
              @click.prevent="approveOrder(props.item)"
            >{{ $t('ubr.Approve_order') }}</a>
          </div>

          <!-- Bouton pour la demande de confirmation-->
          <div v-if="props.item.statut === 2 && props.item.etape === 1">
            <a
              href=""
              @click.prevent="demandeDeConfirmation(props.item)"
            >{{ $t('order.operation.action.Confirmation_request') }}</a>
          </div>
        </template>

        <!-- Pour le développement, on affiche le status-->
        <div v-if="env === 'development'">
          <span
            class="red--text"
          >Étape {{ props.item.etape }}</span>
        </div>
      </template>

      <!-- Type et mode de paiement-->
      <template
        slot="item.type"
        slot-scope="props"
      >
        <div>
          <!-- Mode de paiment-->
          <!-- Payable par code de facturation( UQAM UBR)-->
          <template v-if="props.item.mode_paiement === 1">
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  color="green"
                  large
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-book-open-variant
                </v-icon>
              </template>
              <span>UBR</span>
            </v-tooltip>
          </template>
          <!-- Carte de crédit-->
          <template v-if="props.item.mode_paiement === 2">
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  large
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-credit-card
                </v-icon>
              </template>
              <span>{{ $t('order.Credit_card') }}</span>
            </v-tooltip>
          </template>

          <!-- Type-->
          <!-- Nouvelle ligne-->
          <template v-if="props.item.type === 1">
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  color="grey lighten-1"
                  large
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-sim-outline
                </v-icon>
              </template>
              <span>{{ $t('order.type.1') }}</span>
            </v-tooltip>
          </template>
          <!-- Migration-->
          <template v-if="props.item.type === 2">
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  color="grey lighten-1"
                  large
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-sim
                </v-icon>
              </template>
              <span>{{ $t('order.type.2') }}</span>
            </v-tooltip>
          </template>
          <!-- Achat d'équipement-->
          <template v-if="props.item.type === 3">
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  color="grey lighten-1"
                  large
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ (props.item.type_equipement === 1) ? 'mdi-cellphone-nfc': 'mdi-mini-sd' }}
                </v-icon>
              </template>
              <span>{{ $t('order.type.3') }}</span>
            </v-tooltip>
          </template>
        </div>
      </template>

      <!-- Détails-->
      <template
        slot="item.details"
        slot-scope="props"
      >
        <!-- Nouvelle ligne-->
        <template v-if="props.item.type === 1">
          <!-- Forfait-->
          <div>
            <span class="font-weight-medium">{{ $t('contract.Flat_rate') }}: </span>
            <span> {{ getForfaitName(props.item.forfait) }}</span>
          </div>
          <!-- No commande-->
          <div v-if="props.item.fournisseur_no_commande">
            <span class="font-weight-bold">{{ $t('order.No_order') }}: </span>
            <span>{{ props.item.fournisseur_no_commande }}</span>
          </div>
          <!-- Numéro de téléphone-->
          <div>
            <span class="font-weight-medium">{{ $t('order.No_phone') }}: </span>
            <span v-if="props.item.no_telephone">{{ props.item.no_telephone | phoneNumberFormat }}</span>
            <span v-else> {{ $t('To_come') }} ...</span>
          </div>
        </template>

        <!-- Migration-->
        <template v-if="props.item.type === 2">
          <!-- Forfait-->
          <div class="mt-4">
            <span class="font-weight-medium">{{ $t('contract.Flat_rate') }}: </span>
            <span> {{ getForfaitName(props.item.forfait) }}</span>
          </div>
          <!-- No commande-->
          <div v-if="props.item.fournisseur_no_commande">
            <span class="font-weight-bold">{{ $t('order.No_order') }}: </span>
            <span>{{ props.item.fournisseur_no_commande }}</span>
          </div>
          <div>
            <span class="font-weight-medium">{{ $t('contract.Provider') }}: </span>
            <!-- Si Koodo, PC mobile, Public mobile, Telus-->
            <v-icon
              v-if="props.item.migration_type !== 3"
              class="ml-1"
              left
              dark
              color="yellow accent-3"
            >
              mdi-alert
            </v-icon>
            {{ props.item.migration_fournisseur }}

            <!-- Numéro temporaire-->
            <!-- Pas de numéro temporaire-->
            <!-- Bouton pour numéro temporaire. Si le statut est 7 et si le fournisseur est Koodo, PC mobile, Public mobile-->
            <!-- Si il y a un numéro temporaire -->
            <div v-if="props.item.no_telephone_temporaire">
              <span class="font-weight-medium">No temp:</span>
              <span> {{ props.item.no_telephone_temporaire | phoneNumberFormat }}</span>
            </div>
          </div>
          <div>
            <span class="font-weight-medium">{{ $t('order.No_to_migrate') }}: </span>
            <span> {{ props.item.no_telephone | phoneNumberFormat }}</span>
          </div>
          <!-- Numéro temporaire-->
          <!-- Numéro temporaire. Si le statut est 7 et si le fournisseur est Koodo, PC mobile, Public mobile-->
          <div
            v-if="props.item.migration_type === 3"
          >
            <span class="font-weight-medium">No tmp: </span>
            <span
              v-if="props.item.no_telephone_temporaire"
              class="font-italic"
            > {{ props.item.no_telephone_temporaire | phoneNumberFormat }}</span>
            <span
              v-else
              class="font-italic"
            > {{ $t('To_come') }} ...</span>
          </div>
        </template>

        <!-- Achat d'équipement-->
        <template v-if="props.item.type === 3">
          <div class="mt-4">
            <span class="font-weight-medium">{{ $t('Model') }}: </span>
            <!-- Si AppareilID-->
            <span>{{ props.item.description_equipement }}</span>
          </div>
          <div>
            <span class="font-weight-medium">{{ $t('contract.calls_detail.Cost') }}: </span>
            <span> {{ props.item.cout_facture }} $</span>
          </div>
          <div>
            <span class="font-weight-medium">No CMD {{ parameterFournisseur.toUpperCase() }}: </span>
            <span> {{ props.item.fournisseur_no_commande }}</span>
          </div>
          <div>
            <span class="font-weight-medium">No facture {{ parameterFournisseur.toUpperCase() }}: </span>
            <a
              href=""
              @click.prevent="getFileBillingProvider(props.item.ClientId, props.item.FactureId, props.item.fournisseur_no_facture)"
            > {{ props.item.fournisseur_no_facture }}</a>
          </div>
          <div>
            <span
              v-if="props.item.livraison_no_suivi"
              class="font-weight-medium"
            >{{ $t('order.Tracking_number') }}: </span>
            <span> {{ props.item.livraison_no_suivi }}</span>
          </div>
          <div>
            <span class="font-weight-medium">No facture {{ $company.toUpperCase() }}: </span>
            <span> {{ props.item.FactureId }}</span>
          </div>
          <div>
            <span
              v-if="props.item.no_telephone"
              class="font-weight-medium"
            >{{ $t('order.No_phone') }}: </span>
            <span> {{ props.item.no_telephone | phoneNumberFormat }}</span>
          </div>
        </template>

        <!-- Livraison ou cuillette-->
        <div>
          <!-- Livraison-->
          <span v-if="props.item.livraison">{{ $t('order.Delivery') }}</span>
          <!-- Cuiellette-->
          <template v-else>
            <div>
              <span>{{ $t('order.Pickup') }}: </span>
              <span>{{ getComptoirName(props.item.ComptoirId) }}</span>
            </div>
            <div>
              <span class="red--text font-weight-bold">{{ getPickupDate(props.item.rdv_date) }}</span>
              <v-btn
                v-if="props.item.rdv_date"
                icon
                @click="editAppointment(props.item)"
              >
                <v-icon
                  class="mr-2"
                  size="x-large"
                  color="grey"
                >
                  mdi-calendar-edit-outline
                </v-icon>
              </v-btn>
            </div>
          </template>
        </div>

        <!-- No Carte SIM-->
        <div>
          <template v-if="props.item.no_carte_sim">
            <div>
              <span class="font-weight-bold">SIM: </span>
              <span class="red--text font-weight-bold">{{ props.item.no_carte_sim }}</span>
            </div>
          </template>
        </div>
      </template>

      <!-- Client-->
      <template
        slot="item.client"
        slot-scope="props"
      >
        <div>
          <span>{{ props.item.Client.nom.toUpperCase() }}, {{ props.item.Client.prenom.toUpperCase() }}</span>
        </div>
        <div>
          <span>{{ props.item.Client.email }}</span>
        </div>
        <div>
          <span>{{ props.item.Client.telephone1 | phoneNumberFormat }}</span>
        </div>

        <!-- Commander Par-->
        <!-- if 'ClientIdPar' est pas null-->
        <template v-if="props.item.ClientIdPar">
          <!-- if 'ClientIdPar' est différent de 'ClientId'-->
          <template v-if="props.item.ClientIdPar !== props.item.ClientId">
            <div>
              <span class="font-weight-bold red--text">{{ $t('By') }}: </span>
              <span class="red--text">{{ props.item.ClientPar.email }}</span>
            </div>
          </template>
        </template>
      </template>

      <!-- Comment-->
      <template
        slot="item.comment"
        slot-scope="props"
      >
        <div class="mt-4">
          <span class="font-weight-medium">{{ $t('order.Ordered') }}: </span>
          <span> {{ props.item.createdAt | date }}</span>
        </div>
        <div>
          <span class="font-weight-medium">{{ $t('order.Confirmed') }}: </span>
          <span> {{ props.item.date_confirmee | date }}</span>
        </div>
        <div v-if="props.item.date_approuvee_annulee">
          <span class="font-weight-medium">{{ $t('order.Approved/Rejected') }}: </span>
          <span> {{ props.item.date_approuvee_annulee | date }}</span>
        </div>
        <div v-if="props.item.approuvee_par">
          <span class="font-weight-medium">{{ $t('By') }}: </span>
          <span> {{ props.item.approuvee_par }}</span>
        </div>
        <div class="mb-4">
          <span class="font-weight-medium">{{ $t('Comment') }}: </span>
          <span
            v-if="props.item.note"
            class="font-italic"
          > {{ getComment(props.item.note) }}</span>
        </div>
      </template>

      <!-- Action-->
      <template
        slot="item.edit"
        slot-scope="props"
      >
        <!-- Éditer-->
        <v-btn
          class="mt-1"
          fab
          dark
          x-small
          color="success"
          :title="$t('Edit')"
          @click="edit(props.item.id)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <!-- Supprimer-->
        <v-btn
          :disabled="props.item.statut === -1 || props.item.statut === 8"
          class="ml-2 mt-1"
          fab
          light
          x-small
          color="red"
          :title="$t('Cancel')"
          @click="cancel(props.item.id)"
        >
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
      />
      <v-row
        justify="center"
        align="center"
      >
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="itemsPerPage"
            :items="itemsPage"
            :label="$t('table.Items_per_page')"
          />
        </v-col>
      </v-row>
    </div>

    <!-- DIALOG ÉTAPE-->
    <!--  Dialog Prix pour un appareil-->
    <v-dialog
      v-model="dialog.dialogMissingCost"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">{{ $t('order.operation.Enter_cost') }}</span>
          <v-icon
            aria-label="Close"
            @click="dialog.dialogMissingCost = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <client-commande-demo-uqam-dialog-missing-cost
              ref="ClientCommandeDemoUqamDialogMissingCost"
              @close-dialog="closeDialog"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--  Dialog numéro de commande-->
    <v-dialog
      v-model="dialog.dialogMissingNoOrder"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">{{ $t('order.operation.Order_number') }}</span>
          <v-icon
            aria-label="Close"
            @click="dialog.dialogMissingNoOrder = false"
          >
            mdi-close
          </v-icon>
          <div class="mt-2">
            <span>{{ $t('order.swal.text.Enter_Telus_order_number') }}</span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <client-commande-demo-uqam-dialog-missing-no-order
              ref="ClientCommandeDemoUqamDialogMissingNoOrder"
              @close-dialog="closeDialog"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--  Dialog numéro de commande pour pour une migration-->
    <v-dialog
      v-model="dialog.dialogMissingNoOrderMigration"
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">{{ $t('order.operation.action.Request_migration') }}</span>
          <v-icon
            aria-label="Close"
            @click="dialog.dialogMissingNoOrderMigration = false"
          >
            mdi-close
          </v-icon>
          <div
            class="mt-2 wrap-text"
            v-html="$t('order.swal.text.Request_migration')"
          />
        </v-card-title>
        <v-card-text>
          <v-container>
            <client-commande-demo-uqam-dialog-missing-no-order-migration
              ref="ClientCommandeDemoUqamDialogMissingNoOrderMigration"
              @close-dialog="closeDialog"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--  Dialog numéro de téléphone-->
    <v-dialog
      v-model="dialog.dialogMissingNoPhoneNumber"
      max-width="500px"
    >
      <v-card>
        <div class="text-center">
          <v-icon
            class="mt-5"
            size="78px"
            color="blue"
          >
            mdi-comment-text-outline
          </v-icon>
        </div>
        <v-card-title>
          <span class="text-h2">{{ $t('order.swal.title.Assign_phone_number') }}</span>
          <v-icon
            aria-label="Close"
            @click="dialog.dialogMissingNoPhoneNumber = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <br>
        <v-card-subtitle class="text-center mt-4">
          <span
            class="text-h5"
            v-html="$t('order.swal.text.Assign_phone_number')"
          />
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <client-commande-demo-uqam-dialog-missing-no-phone
              ref="ClientCommandeDemoUqamDialogMissingNoPhone"
              @close-dialog="closeDialog"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--  Dialog manque le coût-->
    <v-dialog
      v-model="dialog.dialogMissingNoBill"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">{{ $t('Billing') }}</span>
          <v-icon
            aria-label="Close"
            @click="dialog.dialogMissingNoBill = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <client-commande-demo-uqam-dialog-missing-no-bill
              ref="ClientCommandeDemoUqamDialogMissingNoBill"
              @close-dialog="closeDialog"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--  Dialog Action Confirmer la requête-->
    <v-dialog
      v-model="dialog.confirmationRequestClient"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">{{ $t('order.operation.action.Inform_client') }}</span>
          <v-icon
            aria-label="Close"
            @click="dialog.confirmationRequestClient = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- Envoyer un email-->
            <v-btn
              color="success"
              elevation="2"
              @click="confirmationRequest(true)"
            >
              {{ $t('order.operation.Send_email') }}
            </v-btn>
            <!-- Confimer verbalement (Seulement pour les UBR)-->
            <!-- <v-btn
              v-if="dialogCommande.mode_paiement === 1"
              color="white"
              elevation="2"
              @click="confirmationRequest(false)"
            >
              {{ $t('order.operation.Verbal_confirmation') }}
            </v-btn> -->
            <br>
            <br>
            <hr>
            <br>
            <br>
            <div v-html="dialogMessage" />
          </v-container>
        </v-card-text>
        <p class="font-weight-thin mr-1 text-right caption">
          {{ dialogRef }}
        </p>
      </v-card>
    </v-dialog>

    <!--  Dialog Action Informer le client-->
    <v-dialog
      v-model="dialog.informerClient"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">{{ $t('order.operation.action.Inform_client') }}</span>
          <v-icon
            aria-label="Close"
            @click="dialog.informerClient = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- Envoyer un email-->
            <v-btn
              color="success"
              elevation="2"
              @click="informClient"
            >
              {{ $t('order.operation.action.Inform_client') }}
            </v-btn>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <div v-html="dialogMessage" />
          </v-container>
        </v-card-text>
        <p class="font-weight-thin mr-1 text-right caption">
          {{ dialogRef }}
        </p>
      </v-card>
    </v-dialog>

    <!--  Dialog Action Migrer Ligne-->
    <v-dialog
      v-model="dialog.migrerLigne"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">{{ $t('order.Line_migration_request') }}</span>
          <v-icon
            aria-label="Close"
            @click="dialog.migrerLigne = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- Envoyer un email-->
            <v-btn
              color="success"
              elevation="2"
              @click="migrateLine"
            >
              {{ $t('order.operation.Send_email') }} ({{ dialogEmailTo }})
            </v-btn>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <div v-html="dialogMessage" />
          </v-container>
        </v-card-text>
        <p class="font-weight-thin mr-1 text-right caption">
          {{ dialogRef }}
        </p>
      </v-card>
    </v-dialog>

    <!--  Dialog fixer rendez-vous-->
    <v-dialog
      v-model="dialog.dialogMakeAppointment"
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h2">{{ $t('order.operation.action.Make_appointment') }}</span>
          <v-icon
            aria-label="Close"
            @click="dialog.dialogMakeAppointment = false"
          >
            mdi-close
          </v-icon>
          <div class="mt-2">
            <v-row
              justify="center"
              align="center"
            />
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row
              justify="center"
              align="center"
            >
              <client-commande-demo-uqam-dialog-make-appointment
                ref="ClientCommandeDemoUqamDialogMakeAppointment"
                @close-dialog="closeDialog"
              />
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG EDITION-->
    <!-- COMMANDER UNE LIGNE CELLULAIRE (Carte de crédit Type 2)-->
    <v-dialog
      v-model="dialog3"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog3 = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  large
                >
                  mdi-credit-card
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>{{ $t('order.Order_cell_line') }}</h3>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('order.Payable_by_credit_card') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <!-- COMMANDER UNE LIGNE CELLULAIRE (Carte de crédit Type 2)-->
        <order-new-line-credit
          ref="orderNewLineCredit"
          :client="client"
          :state="state"
          :parameter-pickup-delivery-type1="parameterPickupDeliveryType1"
          :parameter-pickup-delivery-type2="parameterPickupDeliveryType2"
          :parameter-mode-paiement="parameterModePaiement"
          @submit="submit"
        />
      </v-card>
    </v-dialog>

    <!-- COMMANDER UNE LIGNE CELLULAIRE (UBR Type 1)-->
    <v-dialog
      v-model="dialog1"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="green"
        >
          <v-btn
            icon
            dark
            @click="dialog1 = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  large
                >
                  mdi-book-open-variant
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>{{ $t('order.Order_cell_line') }}</h3>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('order.Payable_by_account') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <!-- Composant pour COMMANDER UNE LIGNE CELLULAIRE (UBR Type 1)-->
        <order-new-line-ubr
          ref="orderNewLineUbr"
          :client="client"
          :state="state"
          :parameter-pickup-delivery-type1="parameterPickupDeliveryType1"
          :parameter-pickup-delivery-type2="parameterPickupDeliveryType2"
          :parameter-longueur-code-facturation="parameterLongueurCodeFacturation"
          @submit="submit"
        />
      </v-card>
    </v-dialog>

    <!-- COMMANDER UN TELEPHONE CELLULAIRE (UBR Type 1)-->
    <v-dialog
      v-model="dialog2"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="green"
        >
          <v-btn
            icon
            dark
            @click="dialog2 = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  large
                >
                  mdi-phone
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>{{ $t('order.Order_cell_phone') }}</h3>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('order.Payable_by_account') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <!-- COMMANDER UN TELEPHONE CELLULAIRE (UBR Type 1)-->
        <order-new-phone-ubr
          ref="orderNewPhoneUbr"
          :client="client"
          :state="state"
          :parameter-pickup-delivery-type3="parameterPickupDeliveryType3"
          :parameter-longueur-code-facturation="parameterLongueurCodeFacturation"
          @submit="submit"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  // Components Dialog
  import ClientCommandeDemoUqamDialogMissingCost from '@/components/Client/Commande/dialog/MissingCost'
  import ClientCommandeDemoUqamDialogMissingNoPhone from '@/components/Client/Commande/dialog/MissingNoPhone'
  import ClientCommandeDemoUqamDialogMissingNoOrder from '@/components/Client/Commande/dialog/MissingNoOrder'
  import ClientCommandeDemoUqamDialogMissingNoOrderMigration from '@/components/Client/Commande/dialog/MissingNoOrderMigration'
  import ClientCommandeDemoUqamDialogMissingNoBill from '@/components/Client/Commande/dialog/MissingNoBill'
  import ClientCommandeDemoUqamDialogMakeAppointment from '@/components/Client/Commande/dialog/MakeAppointment'
  // Components Edit
  import OrderNewLineUbr from '@/components/Client/Order/components/OrderNewLineUbr'
  import OrderNewPhoneUbr from '@/components/Client/Order/components/OrderNewPhoneUbr'
  import OrderNewLineCredit from '@/components/Client/Order/components/OrderNewLineCredit'
  // Services
  import CommandesService from '@/services/01Cell/CommandesService'
  import MessagesService from '@/services/01Cell/MessagesService'
  import ContratsService from '@/services/01Cell/ContratsService'
  import S3 from '@/services/S3'
  // Utils
  import { orderStep } from '@/utils/constants/order/step'
  // Mixins
  import { MyOrders } from '@/mixins/Client/Order/MyOrders'
  // Other
  import { mapGetters, mapState } from 'vuex'
  import { EventBus } from '@/utils/event-bus'
  import Swal from 'sweetalert2'
  import { format, parseISO } from 'date-fns'
  import { fr, enUS } from 'date-fns/esm/locale'
  window.dateFnsLocales = {
    fr,
    en: enUS,
  }
  export default {
    name: 'ClientCommandeDemoUqamComponent',
    components: {
      ClientCommandeDemoUqamDialogMissingCost,
      ClientCommandeDemoUqamDialogMissingNoPhone,
      ClientCommandeDemoUqamDialogMissingNoOrder,
      ClientCommandeDemoUqamDialogMissingNoOrderMigration,
      ClientCommandeDemoUqamDialogMissingNoBill,
      ClientCommandeDemoUqamDialogMakeAppointment,
      OrderNewLineUbr,
      OrderNewPhoneUbr,
      OrderNewLineCredit,
    },
    mixins: [MyOrders],
    props: {
      tableData: Array,
      pickupComptoir: Array,
    },
    data () {
      return {
        env: process.env.NODE_ENV,
        orderStep: orderStep,
        state: 'edit',
        client: null,
        language: this.$i18n.t('Language'),
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
        itemsPage: [10, 25, 50, 100],
        headers: [
          { text: 'No', value: 'id', sortable: true, class: 'text-h4 primary--text', width: '75px' },
          { text: this.$i18n.t('Status'), value: 'statut', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Step'), value: 'action', sortable: false, class: 'text-h4 primary--text' },
          { text: 'Type', value: 'type', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Details'), value: 'details', sortable: false, class: 'text-h4 primary--text' },
          { text: 'Client', value: 'client', sortable: false, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Comment'), value: 'comment', sortable: false, class: 'text-h4 primary--text', width: '250px' },
          { text: 'Action', value: 'edit', sortable: false, align: 'end', class: 'text-h4 primary--text' },
        ],
        statusInfo: {
          1: {
            icon: 'circle-outline',
            color: 'green',
          },
          2: {
            icon: 'email-alert',
            color: 'green',
          },
          3: {
            icon: 'thumb-up',
            color: 'blue',
          },
          4: {
            icon: 'truck-delivery',
            color: 'orange',
          },
          5: {
            icon: 'account',
            color: 'green',
          },
          6: {
            icon: 'truck-check',
            color: 'green',
          },
          7: {
            icon: 'account-question',
            color: 'grey',
          },
          8: {
            icon: 'check-circle',
            color: 'green',
          },
          '-1': {
            icon: 'close-circle-outline',
            color: 'red',
          },
        },
        dialog1: false,
        dialog2: false,
        dialog3: false,
        dialog: {
          dialogMissingCost: false,
          dialogMissingNoOrder: false,
          dialogMissingNoOrderMigration: false,
          dialogMissingNoPhoneNumber: false,
          dialogMissingNoBill: false,
          dialogMakeAppointment: false,
          confirmationRequestClient: false,
          informerClient: false,
          migrerLigne: false,
        },
        dialogRef: null,
        dialogMessage: null,
        dialogEmailSujet: null,
        dialogEmailTo: null,
        dialogCommande: {
          mode_paiement: null,
        },
        informerClientNextStatut: null,
        informerClientNextStep: null,
      }
    },

    computed: {
      ...mapState(['userInfo']),
      ...mapGetters('parameters', {
        parameterContratType2Max: 'getContratType2Max',
        parameterPickupDeliveryType1: 'getPickupDeliveryType1',
        parameterPickupDeliveryType2: 'getPickupDeliveryType2',
        parameterPickupDeliveryType3: 'getPickupDeliveryType3',
        parameterModePaiement: 'getModePaiement',
        parameterLongueurCodeFacturation: 'getLongueurCodeFacturation',
        parameterAdresseCourrielFournisseur: 'getAdresseCourrielFournisseur',
        parameterWebSite: 'getWebSite',
        parameterCodeFacturation: 'getCodeFacturation',
        parameterFournisseur: 'getFournisseur',
      }),
    },

    methods: {
      getComptoirName (comptoirId) {
        const findComptoir = this.pickupComptoir.find(x => x.id === comptoirId)
        if (findComptoir) return findComptoir.item
        return null
      },
      async getFileBillingProvider (clientId, idFacture, IdfactureProvider) {
        try {
          const params = {
            bucket: 'cell-facturation',
            key: `clients/${clientId}/factures/${idFacture}/${IdfactureProvider}.pdf`,
            expires: 60,
          }
          const getSignedUrl = await S3.getSignedUrl(params)
          window.open(getSignedUrl, '_blank')
        } catch (error) {
          console.log(error)
        }
      },
      getPickupDate (date) {
        if (date) return format(parseISO(date), `EEEE, d MMMM yyyy ${this.$i18n.t('time.at')} H:mm`, { locale: window.dateFnsLocales[this.language] })
        return null
      },
      handleFunctionCall (functionName, item, messageNo) {
        // action.fonction.nom
        // action.fonction.messageNo
        this[functionName](item, messageNo)
      },
      getForfaitName (forfait) {
        // Par défaut type 1
        let forfaitName = this.$i18n.t('order.Voice_only')

        if (forfait === 2) forfaitName = this.$i18n.t('order.Data_only')
        if (forfait === 3) forfaitName = this.$i18n.t('order.Voice_data')
        return forfaitName
      },
      getComment (note) {
        if (!note) return ''
        const newNote = note.slice(0, 255)
        const dot = note.length > 255 ? '...' : ''
        return `${newNote} ${dot}`
      },
      refreshTable () {
        // Refresh à la page 1
        this.page = 1
      },
      getStatusAwaitingBillNumber (item) {
        // Si il n'y a pas de numéro de facture
        if (!item.FactureId) return this.$i18n.t('order.operation.action.Awaiting_bill_number')
        // Si il n'y a pas de fichier de facture
        if (!item.ifPDFFacture) return this.$i18n.t('order.operation.action.Awaiting_bill_file')

        // Si il y a un fichier de facture et un numéro de facture on passe au statut 6
        const commande = {
          statut: 6,
        }
        CommandesService.update(commande, item.id).then(response => {
          // On update la liste dans le composant 'OperationCommande.vue'
          EventBus.$emit('update-commande', response)
        })
        return ''
      },
      closeDialog () {
        this.dialog.dialogMissingCost = false
        this.dialog.dialogMissingNoPhoneNumber = false
        this.dialog.dialogMissingNoOrder = false
        this.dialog.dialogMissingNoOrderMigration = false
        this.dialog.dialogMissingNoBill = false
        this.dialog.dialogMakeAppointment = false
        this.dialog.confirmationRequestClient = false
        this.dialog.informerClient = false
        this.dialog.migrerLigne = false
      },
      getStatusIcon (status) {
        return `mdi-${this.statusInfo[status].icon}`
      },
      getStatusIconColor (status) {
        return this.statusInfo[status].color
      },
      addDevicePrice (item) {
        // // Si il y a un appareil, on mets le coût
        // if (item.Appareil) item.cout_at = item.Appareil.prix
        this.dialog.dialogMissingCost = true
        this.$nextTick(() => {
          this.$refs.ClientCommandeDemoUqamDialogMissingCost.getData(JSON.parse(JSON.stringify(item)))
        })
      },
      awaitingPhoneNumber (item) {
        this.dialog.dialogMissingNoPhoneNumber = true
        this.$nextTick(() => {
          this.$refs.ClientCommandeDemoUqamDialogMissingNoPhone.getData(JSON.parse(JSON.stringify(item)))
        })
      },
      awaitingOrderNumber (item) {
        this.dialog.dialogMissingNoOrder = true
        this.$nextTick(() => {
          this.$refs.ClientCommandeDemoUqamDialogMissingNoOrder.getData(JSON.parse(JSON.stringify(item)))
        })
      },
      awaitingOrderNumberMigration (item) {
        this.dialog.dialogMissingNoOrderMigration = true
        this.$nextTick(() => {
          this.$refs.ClientCommandeDemoUqamDialogMissingNoOrderMigration.getData(JSON.parse(JSON.stringify(item)))
        })
      },
      async migrateLineDialog (item) {
        // Start Spinner
        this.$loading.show()
        try {
          // On affiche le courriel que l'on va envoyer au client dans le dialog
          // Récupération des messages
          const message = await MessagesService.replace(23, item.Client.langue)
          // Message
          const messageHtml = message.body.replace('[|COMMANDES_NO_TELEPHONE|]', `(${item.no_telephone.slice(0, 3)}) ${item.no_telephone.slice(3, 6)}-${item.no_telephone.slice(6)}`)
            .replace('[|COMMANDES_MIGRATION_NO_CONTRAT|]', item.migration_no_contrat)
            .replace('[|COMMANDES_FORFAIT|]', this.$i18n.t(`order.flat_rate.${item.forfait}`))

          // On mets les paramètres dans les variables
          // this.dialogMessage = messageHtml
          // this.dialogCommande = item

          // Ref du message
          this.dialogRef = 'ref: 23'
          // Email Body
          this.dialogMessage = messageHtml
          // Email Subject
          this.dialogEmailSujet = message.subject.replace('[|COMMANDES_NO_TELEPHONE|]', `(${item.no_telephone.slice(0, 3)}) ${item.no_telephone.slice(3, 6)}-${item.no_telephone.slice(6)}`)
          // Email to
          this.dialogEmailTo = this.parameterAdresseCourrielFournisseur

          // Id de la commande
          this.dialogCommande = item

          // Open dialog
          this.dialog.migrerLigne = true
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      reserveSimCardDialog (item) {
        Swal.fire({
          title: 'Attention !',
          html: this.$i18n.t('order.swal.text.reserve_sim_card'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Start Spinner
            this.$loading.show()
            try {
              // On change le statut de la commande
              const updateCommande = {
                statut: 6,
              }
              const response = await CommandesService.update(updateCommande, item.id)
              // On update la liste dans le composant 'OperationCommande.vue'
              EventBus.$emit('update-commande', response)
              // Stop Spinner
              this.$loading.hide()
            } catch (error) {
              console.log(error)
              // Stop Spinner
              this.$loading.hide()
            }
          }
        })
      },
      async migrateLineType2 (item) {
        // Si il y a une numéro temporaire
        if (item.no_telephone_temporaire) {
          Swal.fire({
            title: this.$i18n.t('order.swal.title.migration_sim_card'),
            html: this.$i18n.t('order.swal.text.migration_sim_card'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.$i18n.t('Cancel'),
          }).then(async (result) => {
            if (result.isConfirmed) {
              // Start Spinner
              this.$loading.show()
              try {
                // On change le statut de la commande
                const updateCommande = {
                  statut: 8,
                  sendMail: false,
                }

                const response = await CommandesService.confirmationRequest(item.id, updateCommande)
                // On update la liste dans le composant 'OperationCommande.vue'
                EventBus.$emit('update-commande', response)
                // Stop Spinner
                this.$loading.hide()
              } catch (error) {
                console.log(error)
                // Stop Spinner
                this.$loading.hide()
              }
            }
          })
        } else {
          // Swal
          Swal.fire(
            'Attention !',
            `${this.$i18n.t('order.swal.text.temporary_phone_number')}`,
            'error',
          )
        }
      },
      async temporaryPhoneNumber (item) {
        const inputValue = ''
        const { value: phoneNumber } = await Swal.fire({
          title: this.$i18n.t('order.operation.action.Temporary_phone_number'),
          input: 'text',
          inputLabel: this.$i18n.t('order.swal.text.Enter_temporary_phone_number'),
          inputValue: inputValue,
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return this.$i18n.t('You_need_to_write_something')
            } else {
              if (isNaN(value) || value.length !== 10) return this.$i18n.t('order.swal.text.Enter_valid_temporary_phone_number')
            }
          },
        })

        if (phoneNumber) {
          try {
            // Start Spinner
            this.$loading.show()

            // Contrat Temporaire
            const newContratTemporaire = {
              no: phoneNumber,
              statut: 1,
              fournisseur: this.parameterFournisseur,
              forfait_id: 1, // Voix seulement pour avoir moins de frais
              description: `Numéro temporaire de la commande: ${item.id}`,
              type: 1,
              code_facturation: this.parameterCodeFacturation,
              ClientId: -1,
            }
            await ContratsService.save(newContratTemporaire)

            // Contrat
            // const newContrat = {
            //   no: item.no_telephone,
            //   statut: 1,
            //   fournisseur: 'telus',
            //   forfait_id: item.forfait,
            //   description: item.contrat_label,
            //   type: item.mode_paiement,
            //   code_facturation: item.code_facturation,
            //   paiement_automatique: item.paiement_automatique,
            //   ClientId: item.ClientId,
            //   InscriptionPaiementId: item.InscriptionPaiementId,
            // }
            // const responseContract = await ContratsService.save(newContrat)

            // Commande
            // On mets le numéro temporaire
            const commande = {
              statut: item.livraison ? 3 : 7,
              etape: item.livraison ? 8 : 1,
              // statut: 7,
              // etape: 1,
              no_telephone_temporaire: phoneNumber,
              // ContratId: responseContract.id, // Le contrat dans la commande
            }
            const response = await CommandesService.update(commande, item.id)
            // On update la liste dans le composant 'OperationCommande.vue'
            EventBus.$emit('update-commande', response)

            // Stop Spinner
            this.$loading.hide()
          } catch (error) {
            console.log(error)
            // Stop Spinner
            this.$loading.hide()
          }
        }
      },
      async migrateLine (item) {
        // Close dialog
        this.closeDialog()

        // Start Spinner
        this.$loading.show()
        try {
          // On change le statut de la commande
          const updateCommande = {
            statut: 5,
            etape: 1,
            date_migree: Date.now(),
            mail: {
              mailBody: this.dialogMessage,
              mailSujet: this.dialogEmailSujet,
              mailTo: this.dialogEmailTo,
              commandePdf: false,
            },
          }
          const response = await CommandesService.migrateLine(this.dialogCommande.id, updateCommande)
          // On update la liste dans le composant 'OperationCommande.vue'
          EventBus.$emit('update-commande', response)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async migrateClose (item) {
        Swal.fire({
          title: 'Attention !',
          html: this.$i18n.t('order.swal.text.telus_migrate_confirmation'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Start Spinner
            this.$loading.show()
            try {
              // Récupération des messages
              const message = await MessagesService.replace(19, item.Client.langue)

              // Crée un nouveau contrat ou mise à du contrat
              let responseContrat
              const newContrat = {
                no: item.no_telephone,
                statut: 1,
                fournisseur: this.parameterFournisseur,
                forfait_id: item.forfait,
                description: item.contrat_label,
                type: item.mode_paiement,
                code_facturation: item.code_facturation,
                paiement_automatique: item.paiement_automatique,
                ClientId: item.ClientId,
                InscriptionPaiementId: item.InscriptionPaiementId,
              }

              // Si le contrat existe, on le mets à jour
              if (item.ContratId) responseContrat = await ContratsService.update(newContrat, item.ContratId)
              // On ajoute le contrat
              if (!item.ContratId) responseContrat = await ContratsService.save(newContrat)

              // On change le statut de la commande
              const updateCommande = {
                statut: 8,
                etape: 1,
                ContratId: responseContrat.id,
                sendMail: true,
                mail: {
                  mailBody: message.body,
                  mailSujet: message.subject,
                  mailTo: item.Client.email,
                  commandePdf: true,
                },
              }

              const response = await CommandesService.confirmationRequest(item.id, updateCommande)
              // // On update la liste dans le composant 'OperationCommande.vue'
              EventBus.$emit('update-commande', response)
              // Stop Spinner
              this.$loading.hide()
            } catch (error) {
              console.log(error)
              // Stop Spinner
              this.$loading.hide()
            }
          }
        })
      },
      ordered (item) {
        this.dialog.dialogMissingNoBill = true
        this.$nextTick(() => {
          this.$refs.ClientCommandeDemoUqamDialogMissingNoBill.getData(JSON.parse(JSON.stringify(item)))
        })
      },
      makeAppointment (item) {
        // On regarde si le client de la commande a déjà une commande avec un rdv. Si oui, on lui propose de mettre la même date que la commande
        const filterUserOrder = this.getDateOrderMakeAppointment(item)
        this.dialog.dialogMakeAppointment = true
        this.$nextTick(() => {
          this.$refs.ClientCommandeDemoUqamDialogMakeAppointment.getData(JSON.parse(JSON.stringify(item)), true, true, filterUserOrder)
        })
      },
      editAppointment (item) {
        // On regarde si le client de la commande a déjà une commande avec un rdv. Si oui, on lui propose de mettre la même date que la commande
        const filterUserOrder = this.getDateOrderMakeAppointment(item)

        this.dialog.dialogMakeAppointment = true
        this.$nextTick(() => {
          this.$refs.ClientCommandeDemoUqamDialogMakeAppointment.getData(JSON.parse(JSON.stringify(item)), true, false, filterUserOrder)
        })
      },
      getDateOrderMakeAppointment (item) {
        // On regarde si le client de la commande a déjà une commande avec un rdv. Si oui, on lui propose de mettre la même date que la commande
        const filterUserOrder = this.tableData.filter(x => x.statut !== 8 && x.etape !== 1 && x.rdv_date && x.ClientId === item.ClientId)
        // On enlève les duplicate par l'objet 'id'
        const seen = new Set()
        const duplicateUserOrder = filterUserOrder.filter(item => {
          const duplicate = seen.has(item.rdv_date)
          seen.add(item.rdv_date)
          return !duplicate
        })
        return duplicateUserOrder
      },
      async confirmationRequestDialog (item, messageNo) {
        // Start Spinner
        this.$loading.show()
        try {
          // Responsable courriel
          let responsable = null
          if (process.env.VUE_APP_COMPANY === 'uqam') {
            if (item.code_facturation) {
              responsable = await CommandesService.responsableCodeFacturation(item.code_facturation)
            }
          }

          // On affiche le courriel que l'on va envoyer au client dans le dialog
          // Récupération des messages
          const sendToClient = item.ClientIdPar ? item.ClientPar : item.Client

          const message = await MessagesService.replace(messageNo, sendToClient.langue)

          const messageHtml = message.body.replaceAll('[|COMMANDES_CLIENT_PRENOM|]', sendToClient.prenom.charAt(0).toUpperCase() + sendToClient.prenom.slice(1))
            .replaceAll('[|COMMANDES_CLIENT_NOM|]', sendToClient.nom.charAt(0).toUpperCase() + sendToClient.nom.slice(1))
            .replaceAll('[|COMMANDES_COUT_FACTURE|]', item.cout_facture)
            .replaceAll('[|RESPONSABLE_UBR|]', responsable)
            .replaceAll('[|COMMANDES_CODE_FACTURATION|]', item.code_facturation)

          // Ref du message
          this.dialogRef = `ref: ${messageNo}`
          // Email Body
          this.dialogMessage = messageHtml
          // Email Subject
          this.dialogEmailSujet = message.subject
          // Email to
          this.dialogEmailTo = sendToClient.email
          // Id de la commande
          this.dialogCommande = item

          // Open dialog
          this.dialog.confirmationRequestClient = true
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async confirmationRequest (sendMail) {
        // Close dialog
        this.closeDialog()

        // Start Spinner
        this.$loading.show()
        try {
          // Statut
          // Si on envoie un courriel on mets le statut à 2 si pas de courriel on mets le statut 3
          let statut
          let etape
          let response
          // Si il faut envoyer un courriel pour que l'utilisateur confirme
          if (sendMail) {
            statut = 2
            etape = 1

            // On change le statut de la commande et on mets la date d'aujourd'hui
            const updateCommande = {
              statut,
              etape,
              date_demande_confirmation: Date.now(),
              date_confirmee: Date.now(), // Pour la 'date_confirmee'
              sendMail,
              mail: {
                mailBody: this.dialogMessage,
                mailSujet: this.dialogEmailSujet,
                mailTo: this.dialogEmailTo,
                commandePdf: true,
              },
            }
            response = await CommandesService.confirmationRequest(this.dialogCommande.id, updateCommande)
          } else {
            // Pas de courriel
            // Type 1
            if (this.dialogCommande.type === 1) {
              statut = 3
              // Si livraison ou pas
              etape = this.dialogCommande.livraison ? 4 : 1
            } else if (this.dialogCommande.type === 2) {
              if (this.dialogCommande.migration_type === 1) {
                statut = 2
                etape = 2
              } else if (this.dialogCommande.migration_type === 2 || this.dialogCommande.migration_type === 3) {
                statut = 3
                etape = 2
              }
            } else if (this.dialogCommande.type === 3) {
              statut = 3
              if (this.dialogCommande.type_equipement === 1) {
                etape = 1
              } else if (this.dialogCommande.type_equipement === 2) {
                etape = this.dialogCommande.livraison ? 1 : 3
              }
            }

            // Envoie un courriel au responsable de l'UBR
            // On va chercher le responsable de l'UBR
            let responsableEmail = null
            if (process.env.VUE_APP_COMPANY === 'uqam') {
              responsableEmail = await CommandesService.responsableCodeFacturation(this.dialogCommande.code_facturation)
            }

            // On confirme la commande
            const updateCommande = {
              statut,
              etape,
              date_demande_confirmation: Date.now(),
              date_confirmee: Date.now(), // Pour la 'date_confirmee'
              responsableUBR: responsableEmail,
              sendMail,
              mail: {
                mailBody: this.dialogMessage,
                mailSujet: this.dialogEmailSujet,
                mailTo: this.dialogEmailTo,
                commandePdf: true,
              },
            }
            response = await CommandesService.confirmOrder(this.dialogCommande.id, updateCommande)
          }
          // On update la liste dans le composant 'OperationCommande.vue'
          EventBus.$emit('update-commande', response)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async informClientCarteSim (item) {
        Swal.fire({
          title: 'Attention !',
          html: `<br><b>${this.$i18n.t('order.swal.text.fournisseurAutreTelus')} ${item.no_telephone}.</b> <br><br> ${this.$i18n.t('order.swal.text.fournisseurAutre')}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.informClientDialog(item)
          }
        })
      },
      async informClientDialog (item) {
        // Start Spinner
        this.$loading.show()
        try {
          // Message sans livraison par défaut
          let messagesNo = 18
          // Prochain statut 3
          this.informerClientNextStatut = 7
          // Message avec livraison
          if (item.livraison) {
            messagesNo = 26
            if (item.type === 1) {
              // Prochain statut 8
              this.informerClientNextStatut = 8
              // Prochaine étape
              this.informerClientNextStep = 1
            } else if (item.type === 2) {
              this.informerClientNextStep = 1
            } else if (item.type === 3) {
              if (item.type_equipement === 1) {
                // Téléphone
                messagesNo = 27
                // Prochain statut 3
                this.informerClientNextStatut = 4
                // Prochaine étape
                this.informerClientNextStep = 1
              } else if (item.type_equipement === 2) {
                messagesNo = 12
                // Prochain statut
                this.informerClientNextStatut = 4
                // Prochaine étape
                this.informerClientNextStep = 2
              }
            }
          } else {
            // Cueillette
            if (item.type === 1) {
              // Prochain statut 3
              this.informerClientNextStatut = 3
              // Prochaine étape
              this.informerClientNextStep = item.rdv_confirme ? 4 : 3 // Étape 3. En attente du numéro de téléphone. 2 le client doit confirmée sa date de rendez-vous
            } else if (item.type === 2) {
              // Prochain statut 3
              this.informerClientNextStatut = 3
              // Prochaine étape
              this.informerClientNextStep = 4
            } else if (item.type === 3) {
              // Prochain statut 3
              this.informerClientNextStatut = 3
              // Prochaine étape
              this.informerClientNextStep = 5
            }
          }

          // On affiche le courriel que l'on va envoyer au client dans le dialog
          // Récupération des messages
          const message = await MessagesService.replace(messagesNo, item.Client.langue)

          const messageHtml = message.body.replace('[|COMMANDES_CLIENT_PRENOM|]', item.Client.prenom.charAt(0).toUpperCase() + item.Client.prenom.slice(1))
            .replace('[|COMMANDES_CLIENT_NOM|]', item.Client.nom.charAt(0).toUpperCase() + item.Client.nom.slice(1))
            .replaceAll('[|LIVRAISON_NO_SUIVI|]', item.livraison_no_suivi)
            .replace('[|RDV_DATE|]', item.rdv_date ? format(parseISO(item.rdv_date), `EEEE, d MMMM yyyy ${this.$i18n.t('time.at')} H:mm`, { locale: window.dateFnsLocales[item.Client.langue] }) : '')

          // Ref du message
          this.dialogRef = `ref: ${messagesNo}`
          // Email Body
          this.dialogMessage = messageHtml
          // Email Subject
          this.dialogEmailSujet = message.subject
          // Email to
          this.dialogEmailTo = item.Client.email

          this.dialogCommande = item

          // Open dialog
          this.dialog.informerClient = true
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async numeroDeSuiviDialog (item) {
        const inputValue = ''
        const { value: nosuivi } = await Swal.fire({
          title: this.$i18n.t('order.Tracking_number'),
          input: 'text',
          inputLabel: this.$i18n.t('order.swal.text.Enter_tracking_number'),
          inputValue: inputValue,
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return this.$i18n.t('You_need_to_write_something')
            }
          },
        })

        if (nosuivi) {
          try {
            // Start Spinner
            this.$loading.show()
            let statut
            let etape
            if (item.type === 1) {
              statut = item.livraison ? 6 : 7
              etape = item.livraison ? 1 : 1
            } else if (item.type === 2) {
              statut = item.livraison ? 6 : 7
              etape = item.livraison ? 1 : 1
            } else if (item.type === 3) {
              statut = item.livraison ? 6 : 4
              etape = item.livraison ? 1 : 1
            }

            // Commande
            // On mets le numéro de suivi
            const commande = {
              statut,
              etape,
              livraison_no_suivi: nosuivi,
            }
            const response = await CommandesService.update(commande, item.id)
            // On update la liste dans le composant 'OperationCommande.vue'
            EventBus.$emit('update-commande', response)

            // Stop Spinner
            this.$loading.hide()
          } catch (error) {
            console.log(error)
            // Stop Spinner
            this.$loading.hide()
          }
        }
      },
      async informClient () {
        // Close dialog
        this.closeDialog()

        // Start Spinner
        this.$loading.show()
        try {
          // On change le statut de la commande
          const updateCommande = {
            statut: this.informerClientNextStatut,
            etape: this.informerClientNextStep,
            sendMail: true,
            mail: {
              mailBody: this.dialogMessage,
              mailSujet: this.dialogEmailSujet,
              mailTo: this.dialogEmailTo,
              commandePdf: true,
            },
          }
          const response = await CommandesService.confirmationRequest(this.dialogCommande.id, updateCommande)
          // On update la liste dans le composant 'OperationCommande.vue'
          EventBus.$emit('update-commande', response)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async activeSimCard (item) {
        Swal.fire({
          title: this.$i18n.t('order.swal.title.SIM_card_activation'),
          html: this.$i18n.t('order.swal.text.SIM_card_activation'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$i18n.t('order.swal.text.Close_order'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Start Spinner
            this.$loading.show()
            try {
              // Récupération des messages
              const messages = await MessagesService.multiple({ messages: [17, 1] })
              const message = messages.find(item => item.no === 17)
              const signature = messages.find(item => item.no === 1)

              // Sujet
              const subject = message[`sujet_${this.language}`].replace(/<\/?[^>]+(>|$)/g, '')
              // Message
              const messageHtml = message[this.language].replace('[|SIGNATURE|]', signature[this.language])

              // On change le statut de la commande
              const updateCommande = {
                statut: 8,
                etape: 1,
                sendMail: true,
                mail: {
                  mailBody: messageHtml,
                  mailSujet: subject,
                  mailTo: item.Client.email,
                  commandePdf: true,
                },
              }

              const response = await CommandesService.confirmationRequest(item.id, updateCommande)
              // On update la liste dans le composant 'OperationCommande.vue'
              EventBus.$emit('update-commande', response)
              // Stop Spinner
              this.$loading.hide()
            } catch (error) {
              console.log(error)
              // Stop Spinner
              this.$loading.hide()
            }
          }
        })
      },
      async close (item) {
        // Message
        let title = ''
        let message = ''
        // Si on crée le contrat
        let createContract = false

        // Commande de Nouvelle Ligne
        if (item.type === 1) {
          createContract = true
          title = `${this.$i18n.t('Are_you_sure')} ?`
          message = this.$i18n.t('order.swal.text.customer_came_pick_order_type1')
        }
        // Commande de Migration de ligne
        if (item.type === 2) {
          createContract = true
          if (item.migration_type === 3) {
            title = this.$i18n.t('order.swal.title.migration_sim_card')
            message = this.$i18n.t('order.swal.text.fournisseurOtherMessage')
          } else {
            title = this.$i18n.t('order.swal.title.migration_sim_card')
            message = this.$i18n.t('order.swal.text.migration_sim_card')
          }
        }

        // Achat d'équipement
        if (item.type === 3) {
          // Appareil
          if (item.type_equipement === 1) {
            title = this.$i18n.t('order.swal.title.close_order')
            message = this.$i18n.t('order.swal.text.close_type3_typeequipement1')
          } else if (item.type_equipement === 2) {
            // Carte SIM
            title = this.$i18n.t('order.swal.title.close_order')
            message = this.$i18n.t('order.swal.text.new_sim_card')
          }
        }

        Swal.fire({
          title: title,
          html: message,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Start Spinner
            this.$loading.show()
            try {
              // On affiche le courriel que l'on va envoyer au client dans le dialog
              // Récupération des messages
              const message = await MessagesService.replace(20, item.Client.langue)

              // On change le statut de la commande
              const updateCommande = {
                statut: 8,
                etape: 1,
                card_info: item.InscriptionPaiementId ? item.Inscription_paiement : null, // On mets la carte dans le champs card_info et on enlève l'InscriptionPaiementId
                InscriptionPaiementId: null, // On enlève la carte relier à la commande
                sendMail: true,
                mail: {
                  mailBody: message.body,
                  mailSujet: message.subject,
                  mailTo: item.Client.email,
                  commandePdf: true,
                },
              }

              // Création du contrat si il n'y a pas de contrat
              if (createContract && !item.ContratId) {
                const newContrat = {
                  no: item.no_telephone,
                  statut: 1,
                  fournisseur: this.parameterFournisseur,
                  forfait_id: item.forfait,
                  description: item.contrat_label,
                  type: item.mode_paiement,
                  code_facturation: item.code_facturation,
                  paiement_automatique: item.paiement_automatique,
                  ClientId: item.ClientId,
                  InscriptionPaiementId: item.InscriptionPaiementId,
                }
                const responseContrat = await ContratsService.save(newContrat)
                // On mets le contrat dans la commande
                updateCommande.ContratId = responseContrat.id
              }

              const response = await CommandesService.confirmationRequest(item.id, updateCommande)
              // On update la liste dans le composant 'OperationCommande.vue'
              EventBus.$emit('update-commande', response)
              // Stop Spinner
              this.$loading.hide()
            } catch (error) {
              console.log(error)
              // Stop Spinner
              this.$loading.hide()
            }
          }
        })
      },
      async edit (id) {
        // Start Spinner
        this.$loading.show()
        try {
          const item = await CommandesService.view(id)
          // Date de rendez-vous
          if (item.rdv_date) item.date = format(parseISO(item.rdv_date), 'yyyy-MM-dd')
          if (item.rdv_date) item.time = format(parseISO(item.rdv_date), 'HH:mm')

          // On mets les info du client dans la variable 'client'
          this.client = item.Client
          // COMMANDER UNE LIGNE CELLULAIRE (Carte de crédit Type 2)
          if ((item.type === 1 || item.type === 2) && item.mode_paiement === 2) {
            this.dialog3 = true
            this.$nextTick(() => {
              // On envoie l'objet de la commande dans le composant
              this.$refs.orderNewLineCredit.editOrder(item)
            })
          }
          // COMMANDER UNE LIGNE CELLULAIRE (UBR Type 1)
          if ((item.type === 1 || item.type === 2) && item.mode_paiement === 1) {
            this.dialog1 = true
            this.$nextTick(() => {
              // On envoie l'objet de la commande dans le composant
              this.$refs.orderNewLineUbr.editOrder(item)
            })
          }
          // COMMANDER UN TELEPHONE CELLULAIRE (UBR Type 1)
          if (item.type === 3 && item.mode_paiement === 1) {
            this.dialog2 = true
            this.$nextTick(() => {
              // On envoie l'objet de la commande dans le composant
              this.$refs.orderNewPhoneUbr.editOrder(item)
            })
          }
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      cancel (id) {
        Swal.fire({
          title: `${this.$i18n.t('Are_you_sure')}?`,
          html: `${this.$i18n.t('order.cancel_order')} <b>${id}</b>.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Start Spinner
            this.$loading.show()
            try {
              // Saugarde da la commande
              const response = await CommandesService.update({ statut: -1, etape: 1 }, id)
              EventBus.$emit('update-commande', response)

              // Stop Spinner
              this.$loading.hide()

              // Swal
              Swal.fire(
                `${this.$i18n.t('Cancel')}!`,
                `${this.$i18n.t('order.Order_has_been_cancelled')}.`,
                'success',
              )
            } catch (error) {
              console.log(error)
              // Stop Spinner
              this.$loading.hide()
            }
          }
        })
      },
      async approveOrder (order) {
        try {
          // Start Spinner
          this.$loading.show()
          const responsable = await CommandesService.responsableCodeFacturation(order.code_facturation)
          const message = this.$i18n.t('ubr.Approve_order_admin_message').replace('[|RESPONSABLE_UBR|]', responsable)
          // Stop Spinner
          this.$loading.hide()

          await Swal.fire({
            title: `${this.$i18n.t('ubr.Approve_order')}?`,
            html: message,
            input: 'textarea',
            inputLabel: 'Message',
            inputPlaceholder: this.$i18n.t('Comment'),
            inputAttributes: {
              'aria-label': this.$i18n.t('Comment'),
            },
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$i18n.t('order.Approve'),
            cancelButtonText: this.$i18n.t('Cancel'),
            inputValidator: async (value) => {
              if (!value) {
                return this.$i18n.t('ubr.Comment_require')
              } else {
                try {
                  // Start Spinner
                  this.$loading.show()
                  // Statut et Étape
                  const { statut, etape } = this.getNextStatutStep(order)
                  // Note
                  const ancienneNote = order.note ? `\n\n${order.note}` : ''
                  const note = `${this.$i18n.t('ubr.Approved_by_administrator')} ${value}${ancienneNote}`
                  // On confirme la commande
                  const data = { date_approuvee_annulee: Date.now(), approuvee_par: this.userInfo.info.username, statut, etape, note: note }
                  // Sauvegarde da la commande
                  const response = await CommandesService.update(data, order.id)
                  // On update le client
                  EventBus.$emit('update-commande', response)
                  // Stop Spinner
                  this.$loading.hide()
                } catch (error) {
                  console.log(error)
                  // Stop Spinner
                  this.$loading.hide()
                }
              }
            },
          })
          // Swal.fire({
          //   title: `${this.$i18n.t('ubr.Approve_order')}?`,
          //   html: message,
          //   icon: 'warning',
          //   showCancelButton: true,
          //   confirmButtonColor: '#3085d6',
          //   cancelButtonColor: '#d33',
          //   confirmButtonText: this.$i18n.t('order.Approve'),
          //   cancelButtonText: this.$i18n.t('Cancel'),
          // }).then(async (result) => {
          //   if (result.isConfirmed) {
          //     try {
          //       // Start Spinner
          //       this.$loading.show()
          //       // Statut et Étape
          //       const { statut, etape } = this.getNextStatutStep(order)
          //       // On confirme la commande
          //       const data = { date_approuvee_annulee: Date.now(), approuvee_par: this.userInfo.info.username, statut, etape }
          //       console.log(data)
          //       // Saugarde da la commande
          //       // const response = await CommandesService.update(data, order.id)
          //       // EventBus.$emit('update-commande', response)
          //       // On update le client
          //       EventBus.$emit('update-order')
          //     } catch (error) {
          //       console.log(error)
          //       // Stop Spinner
          //       this.$loading.hide()
          //     }
          //   }
          // })
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async demandeDeConfirmation (order) {
        try {
          await Swal.fire({
            title: `${this.$i18n.t('ubr.Approve_order')}?`,
            html: this.$i18n.t('ubr.Confirmed_admin_message'),
            input: 'textarea',
            inputLabel: 'Message',
            inputPlaceholder: this.$i18n.t('Comment'),
            inputAttributes: {
              'aria-label': this.$i18n.t('Comment'),
            },
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$i18n.t('Confirm'),
            cancelButtonText: this.$i18n.t('Cancel'),
            inputValidator: async (value) => {
              if (!value) {
                return this.$i18n.t('ubr.Comment_require')
              } else {
                try {
                  // Start Spinner
                  this.$loading.show()

                  let statut
                  let etape
                  if (order.mode_paiement === 1) {
                    // Approbation du responsable UBR
                    statut = 2
                    etape = 3
                  } else {
                    // Statut et Étape
                    const statutEtape = this.getNextStatutStep(order)
                    statut = statutEtape.statut
                    etape = statutEtape.etape
                  }
                  // Note
                  const ancienneNote = order.note ? `\n\n${order.note}` : ''
                  const note = `${this.$i18n.t('ubr.Confirm_by_operator')} (${this.userInfo.info.username}) ${value}${ancienneNote}`
                  // On confirme la commande
                  const data = { statut, etape, note: note }
                  // Sauvegarde da la commande
                  const response = await CommandesService.update(data, order.id)
                  // On update le client
                  EventBus.$emit('update-commande', response)
                  // Stop Spinner
                  this.$loading.hide()
                } catch (error) {
                  console.log(error)
                  // Stop Spinner
                  this.$loading.hide()
                }
              }
            },
          })
          // Swal.fire({
          //   title: `${this.$i18n.t('ubr.Approve_order')}?`,
          //   html: message,
          //   icon: 'warning',
          //   showCancelButton: true,
          //   confirmButtonColor: '#3085d6',
          //   cancelButtonColor: '#d33',
          //   confirmButtonText: this.$i18n.t('order.Approve'),
          //   cancelButtonText: this.$i18n.t('Cancel'),
          // }).then(async (result) => {
          //   if (result.isConfirmed) {
          //     try {
          //       // Start Spinner
          //       this.$loading.show()
          //       // Statut et Étape
          //       const { statut, etape } = this.getNextStatutStep(order)
          //       // On confirme la commande
          //       const data = { date_approuvee_annulee: Date.now(), approuvee_par: this.userInfo.info.username, statut, etape }
          //       console.log(data)
          //       // Saugarde da la commande
          //       // const response = await CommandesService.update(data, order.id)
          //       // EventBus.$emit('update-commande', response)
          //       // On update le client
          //       EventBus.$emit('update-order')
          //     } catch (error) {
          //       console.log(error)
          //       // Stop Spinner
          //       this.$loading.hide()
          //     }
          //   }
          // })
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      // Fonction appellé des composants des dialogs lorsqu'un utilisateur soumets une commande
      async submit (submitForm) {
        // Close Dialog
        this.dialog1 = false
        this.dialog2 = false
        this.dialog3 = false

        // Start Spinner
        this.$loading.show()
        try {
          // Saugarde da la commande
          const response = await CommandesService.update(submitForm, submitForm.id)
          EventBus.$emit('update-commande', response)

          // Stop Spinner
          this.$loading.hide()

          Swal.fire(
            `${this.$i18n.t('Updated')}!`,
            `${this.$i18n.t('order.Order_has_been_updated')}.`,
            'success',
          )
        } catch (error) {
          // Stop Spinner
          this.$loading.hide()
          console.log(error)
        }
      },
    },
  }
</script>
