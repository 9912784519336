<template>
  <v-container
    id="regular-forms"
    fluid
    tag="section"
  >
    <validation-observer
      ref="obs"
      v-slot="{ invalid, handleSubmit }"
    >
      <form>
        <!--  Administrateur-->
        <v-row
          v-if="ifRoleAdminAndRouteOrder"
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="primary"
              icon="mdi-lock-open"
              :title="$t('Administrator')"
              class="px-5 py-3 mt-3"
            >
              <!-- Type de commande-->
              <v-subheader class="display-1 mt-3">
                {{ $t('Status') }}
              </v-subheader>

              <validation-provider
                v-slot="{ errors, valid }"
                :name="$t('Status')"
                rules="required"
              >
                <v-select
                  v-model="submitForm.statut"
                  :disabled="true"
                  class="ml-4 mt-0"
                  :items="statusAdmin"
                  item-text="item"
                  item-value="valeur"
                  persistent-hint
                  single-line
                  :error-messages="errors"
                  :success="valid"
                  required
                />
              </validation-provider>

              <!-- No de commande du fournisseur-->
              <v-subheader class="display-1 mt-3">
                No CMD {{ parameterFournisseur.toUpperCase() }}
              </v-subheader>

              <v-text-field
                v-model="submitForm.fournisseur_no_commande"
                :disabled="state === 'view'"
                class="ml-4 mt-0"
              />
            </base-material-card>
          </v-col>
        </v-row>

        <!-- Pour qui la commande (Pour la route est 'operation-commande' ou si le client est admin dans la table du client)-->
        <!-- <v-row
          v-if="ifCommanderPour"
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="primary"
              icon="mdi-account"
              :title="$t('order.Order_for')"
              class="px-5 py-3 mt-3"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Client"
                rules="required"
              >
                <v-autocomplete
                  v-model="clientSearch.client"
                  class="ml-4 mt-0"
                  :items="clientSearch.entries"
                  :loading="clientSearch.isLoading"
                  :search-input.sync="clientSearch.search"
                  hide-no-data
                  hide-selected
                  item-text="firstNameName"
                  item-value="id"
                  :placeholder="$t('Start_typing_Search')"
                  append-icon="mdi-account"
                  return-object
                  :error-messages="errors"
                  :success="valid"
                  required
                  @keypress.enter="$event.preventDefault()"
                >
                  <template #selection="data">
                    {{ data.item.firstNameName }}
                  </template>
                  <template #item="data">
                    {{ data.item.firstNameName }}
                  </template>
                </v-autocomplete>
              </validation-provider>
            </base-material-card>
          </v-col>
        </v-row> -->

        <!--  Statut et Type-->
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="primary"
              icon="mdi-book-information-variant"
              :title="$t('order.Status_type')"
              class="px-5 py-3 mt-3"
            >
              <!-- Type de commande-->
              <v-subheader class="display-1 mt-3">
                {{ $t('order.Order_type') }}
              </v-subheader>

              <validation-provider
                v-slot="{ errors, valid }"
                :name="$t('order.Order_type')"
                rules="required"
              >
                <v-radio-group
                  v-model="submitForm.type"
                  :disabled="state === 'view'"
                  row
                  class="ml-4 mt-0"
                  :error-messages="errors"
                  :success="valid"
                  required
                  @change="changeType($event)"
                >
                  <v-radio
                    :label="$t('order.Activation_new_number')"
                    :value="1"
                  />

                  <v-radio
                    :label="$t('order.transfer_number')"
                    :value="2"
                  />
                </v-radio-group>
              </validation-provider>

              <!-- Si c'est un transfert de ligne-->
              <template v-if="submitForm.type === 2">
                <div class="mt-5">
                  <v-subheader class="display-1 mt-3">
                    {{ $t('order.message_migration') }}
                  </v-subheader>
                </div>

                <!-- Numéro à migrer-->
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('order.Phone_number_to_migrate')"
                  :rules="ifNoTelephoneExist"
                >
                  <v-text-field
                    v-model="submitForm.no_telephone"
                    :disabled="state === 'view'"
                    maxlength="10"
                    placeholder="xxxxxxxxxx"
                    class="ml-4 mt-0"
                    :label="$t('order.Phone_number_to_migrate')"
                    auto-grow
                    append-icon="mdi-phone"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>

                <!-- Fournisseur actuel-->
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('contract.Provider')"
                  rules="required"
                >
                  <v-select
                    v-model="submitForm.migration_fournisseur"
                    :disabled="state === 'view'"
                    class="ml-4 mt-0"
                    :label="$t('order.Current_provider')"
                    :items="provider"
                    item-text="valeur"
                    item-value="item"
                    persistent-hint
                    single-line
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>

                <!-- Numéro à temporaire-->
                <!-- Seulement si la route est 'operation-commande' (operation) et pour les fournisseur spécial (Pc Mobile etc...)-->
                <validation-provider
                  v-if="ifRouteOrder && specialProvider"
                  v-slot="{ errors, valid }"
                  :name="$t('order.Temporary_phone_number')"
                  :rules="{ required:true, min:10, max:10, numeric:true, numberPhoneIsDuplicates: submitForm.no_telephone }"
                >
                  <v-text-field
                    v-model="submitForm.no_telephone_temporaire"
                    type="number"
                    placeholder="xxxxxxxxxx"
                    class="ml-4 mt-0"
                    :label="$t('order.Temporary_phone_number')"
                    rows="1"
                    auto-grow
                    append-icon="mdi-phone"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>

                <!-- Numéro du compte-->
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('order.Account_number')"
                  rules="required"
                >
                  <v-text-field
                    v-model="submitForm.migration_no_contrat"
                    :disabled="state === 'view'"
                    class="ml-4 mt-0"
                    :label="$t('order.Account_number')"
                    append-icon="mdi-card-account-details"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>

                <!-- Nom du responsable du compte-->
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('order.Name_account')"
                  rules="required"
                >
                  <v-text-field
                    v-model="submitForm.migration_responsable"
                    :disabled="state === 'view'"
                    class="ml-4 mt-0"
                    :label="$t('order.Name_account')"
                    append-icon="mdi-account"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>

                <!-- Adresse du responsable du compte-->
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('order.Address_account')"
                  rules="required"
                >
                  <v-textarea
                    v-model="submitForm.migration_adresse"
                    :disabled="state === 'view'"
                    class="ml-4 mt-0"
                    :label="$t('order.Address_account')"
                    auto-grow
                    append-icon="mdi-comment"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </template>

              <!-- Type de forfait-->
              <v-subheader class="display-1 mt-3">
                {{ $t('order.Package_type') }}
              </v-subheader>

              <validation-provider
                v-slot="{ errors, valid }"
                :name="$t('order.Package_type')"
                rules="required"
              >
                <v-radio-group
                  v-model="submitForm.forfait"
                  :disabled="state === 'view'"
                  row
                  class="ml-4 mt-0"
                  :error-messages="errors"
                  :success="valid"
                  required
                >
                  <v-radio
                    :label="$t('order.Voice_only')"
                    :value="1"
                  />

                  <v-radio
                    :label="$t('order.Data_only')"
                    :value="2"
                  />

                  <v-radio
                    :label="$t('order.Voice_data')"
                    :value="3"
                  />
                </v-radio-group>
              </validation-provider>

              <!-- Description du contrat-->
              <v-subheader class="display-1 mt-3">
                {{ $t('order.User_use_number') }}
              </v-subheader>

              <validation-provider
                v-slot="{ errors, valid }"
                :name="$t('order.User_use_number')"
                rules="required"
              >
                <v-text-field
                  v-model="submitForm.contrat_label"
                  :disabled="state === 'view'"
                  class="ml-4 mt-0"
                  append-icon="mdi-account"
                  :error-messages="errors"
                  :success="valid"
                  required
                />
              </validation-provider>
            </base-material-card>
          </v-col>
        </v-row>

        <!-- LIVRAISON/CUILLETTE-->
        <v-row
          v-if="showPickupDelivery"
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="primary"
              icon="mdi-truck-fast"
              :title="$t('order.Pickup_Delivery')"
              class="px-5 py-3 mt-3"
            >
              <!-- On affiche le choix si il y a une cueillette et une livraison-->
              <validation-provider
                v-if="parameterPickupDelivery === '0'"
                v-slot="{ errors, valid }"
                :name="$t('order.Pickup_Delivery')"
                rules="required"
              >
                <v-radio-group
                  v-model="submitForm.livraison"
                  :disabled="state === 'view'"
                  row
                  class="ml-4 mt-3"
                  :error-messages="errors"
                  :success="valid"
                  required
                >
                  <!-- Cueillette-->
                  <v-radio
                    :label="$t('order.Pickup')"
                    :value="false"
                  />

                  <!-- Livraison-->
                  <v-radio
                    :label="$t('order.Delivery')"
                    :value="true"
                  />
                </v-radio-group>
              </validation-provider>

              <template v-if="submitForm.livraison !== null">
                <!-- Adresse pour la livraison-->
                <template v-if="submitForm.livraison">
                  <v-subheader class="display-1 mt-3">
                    {{ $t('order.Delivery') }}
                  </v-subheader>

                  <!-- Numéro de suivi-->
                  <!-- Pour les Opérations seulement-->
                  <v-text-field
                    v-if="ifRouteOrder"
                    v-model="submitForm.livraison_no_suivi"
                    :disabled="state === 'view'"
                    class="ml-4 mt-0"
                    :label="$t('order.Tracking_number')"
                  />

                  <!-- Nom-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('Name')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="submitForm.livraison_adresse.nom"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :label="$t('Name')"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>

                  <!-- Prénom-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('FirstName')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="submitForm.livraison_adresse.prenom"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :label="$t('FirstName')"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>

                  <!-- Adresse-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('client.Address')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="submitForm.livraison_adresse.adresse"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :label="$t('client.Address')"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>

                  <!-- Ville-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('client.City')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="submitForm.livraison_adresse.ville"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :label="$t('client.City')"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>

                  <!-- Province-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('client.City')"
                    rules="required"
                  >
                    <v-select
                      v-model="submitForm.livraison_adresse.province"
                      label="Province"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :items="provinces"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>

                  <!-- Code Postale-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('client.ZIP_code')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="submitForm.livraison_adresse.code_postal"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :label="$t('client.ZIP_code')"
                      :error-messages="errors"
                      :success="valid"
                      placeholder="H0H 0H0"
                      required
                    />
                  </validation-provider>
                </template>

                <!-- Cueillette-->
                <template v-else>
                  <v-subheader class="display-1 mt-3">
                    {{ $t('order.Pickup') }}
                  </v-subheader>

                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('Counter')"
                    rules="required"
                  >
                    <v-list
                      shaped
                    >
                      <v-list-item-group
                        v-model="submitForm.ComptoirId"
                        class="ml-4 mt-0"
                        :error-messages="errors"
                        :success="valid"
                        required
                      >
                        <template v-for="(item, i) in getComptoirList">
                          <v-divider
                            v-if="!item"
                            :key="`divider-${i}`"
                          />

                          <v-list-item
                            v-else
                            :key="`item-${i}`"
                            :disabled="state === 'view' || item.id === submitForm.ComptoirId || getComptoirList.length === 1"
                            :value="item.id"
                            active-class="green--text text--accent-4"
                          >
                            <template #default="{ active }">
                              <v-list-item-action>
                                <v-checkbox
                                  :input-value="active"
                                  color="green"
                                />
                              </v-list-item-action>

                              <v-list-item-content>
                                <v-list-item-title v-html="item[language]" />
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </validation-provider>
                </template>
              </template>
            </base-material-card>
          </v-col>
        </v-row>

        <!-- FACTURATION-->
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="primary"
              icon="mdi-currency-usd"
              :title="$t('Billing')"
              class="px-5 py-3 mt-3"
            >
              <!-- Si la commande a été complété le champs 'card_info' est rempli avec la carte venant du champs 'InscriptionPaiementId'-->
              <template v-if="submitForm.card_info">
                <v-list
                  shaped
                >
                  <!-- Si il y a au moins une carte de crédit-->
                  <v-list-item-group
                    class="ml-4 mt-0"
                    required
                    append-icon="mdi-credit-card"
                  >
                    <v-list-item>
                      <v-list-item-action>
                        <v-checkbox
                          :disabled="true"
                          :input-value="true"
                          color="green"
                        />
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{ submitForm.card_info.nom_titulaire }}</v-list-item-title>
                        <v-list-item-subtitle>{{ `${$t('profile.automatic_payment.Credit_card_ending')} ${submitForm.card_info.dernier_chiffre}` }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </template>

              <!-- Commande non terminé-->
              <template v-else>
                <!-- On affiche la sélection entre le paiement manuel et la carte de crédit si la variable 'parameterModePaiement' est 3-->
                <template v-if="parameterModePaiement === '3'">
                  <!-- Mode de paiment-->
                  <v-subheader class="display-1 mt-3">
                    {{ $t('order.Payment_mode') }}
                  </v-subheader>

                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('order.Payment_mode')"
                    rules="required"
                  >
                    <v-radio-group
                      v-model="submitForm.paiement_automatique"
                      :disabled="state === 'view'"
                      row
                      class="ml-4 mt-0"
                      :error-messages="errors"
                      :success="valid"
                      required
                      append-icon="mdi-currency-usd"
                    >
                      <!-- On désactive si on a pas le choix entre le paiement manuel ou automatique-->
                      <v-radio
                        :label="$t('order.Manual_payment')"
                        :value="false"
                        @click="changePaymentMode(false)"
                      />

                      <!-- On désactive si on a pas le choix entre le paiement manuel ou automatique-->
                      <v-radio
                        :label="$t('order.Automatic_payment')"
                        :value="true"
                        @click="changePaymentMode(true)"
                      />
                    </v-radio-group>
                  </validation-provider>
                </template>

                <!-- Si paiement automatique, le client doit choisir sa carte-->
                <template v-if="submitForm.paiement_automatique">
                  <!-- Choix de la carte de crédit-->
                  <v-subheader class="display-1 mt-3">
                    {{ $t('order.Credit_card') }}
                  </v-subheader>

                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('order.Credit_card')"
                    rules="required"
                  >
                    <v-list
                      shaped
                    >
                      <!-- Si pas de carte -->
                      <div
                        v-if="!client.Inscription_paiements.length"
                        class="text-center"
                      >
                        <router-link
                          class="text-h3 mb-1"
                          :to="{ name: 'profile'}"
                        >
                          {{ $t('profile.automatic_payment.Add_card') }}
                        </router-link>
                      </div>

                      <!-- Si il y a au moins une carte de crédit-->
                      <v-list-item-group
                        v-model="submitForm.InscriptionPaiementId"
                        class="ml-4 mt-0"
                        :error-messages="errors"
                        :success="valid"
                        required
                        append-icon="mdi-credit-card"
                      >
                        <template v-for="(card, i) in client.Inscription_paiements">
                          <v-divider
                            v-if="!card"
                            :key="`divider-${i}`"
                          />

                          <v-list-item
                            v-else
                            :key="`item-${i}`"
                            :disabled="state === 'view'"
                            :value="card.id"
                            active-class="green--text text--accent-4"
                          >
                            <template #default="{ active }">
                              <v-list-item-action>
                                <v-checkbox
                                  :disabled="active"
                                  :input-value="active"
                                  color="green"
                                />
                              </v-list-item-action>

                              <v-list-item-content>
                                <v-list-item-title>{{ card.nom_titulaire }}</v-list-item-title>
                                <v-list-item-subtitle>{{ `${$t('profile.automatic_payment.Credit_card_ending')} ${card.dernier_chiffre}` }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </validation-provider>
                </template>

                <div
                  v-if="submitForm.paiement_automatique !== null"
                  class="mt-5 ml-4"
                >
                  <v-icon>
                    mdi-credit-card
                  </v-icon>
                  <span class="red--text ml-1">{{ submitForm.paiement_automatique ? $t('order.My_monthly_charges_auto').toUpperCase() : $t('order.My_monthly_charges_manuel').toUpperCase() }}</span>
                </div>
              </template>
            </base-material-card>
          </v-col>
        </v-row>

        <!-- COMMENTAIRES-->
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="primary"
              icon="mdi-comment"
              :title="$t('Comment')"
              class="px-5 py-3 mt-3"
            >
              <v-textarea
                v-model="submitForm.note"
                :disabled="state === 'view'"
                class="ml-4 mt-3"
                :label="$t('order.comment_description')"
                rows="1"
                auto-grow
                append-icon="mdi-comment"
              />
            </base-material-card>
          </v-col>
        </v-row>

        <!-- CONFIRMATION-->
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              v-if="state !== 'view'"
              :disabled="invalid"
              class="mt-3"
              color="primary"
              @click="handleSubmit(submit)"
            >
              {{ $t('Validate') }}
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
  // Services
  import ListService from '@/services/01Cell/ListService'
  import MessagesService from '@/services/01Cell/MessagesService'
  // Mixins
  import { orders } from '@/mixins/Client/Order/orders'
  import { ClientEdit } from '@/mixins/Client/ClientEdit'
  // Other
  import { mapGetters } from 'vuex'
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'

  export default {
    name: 'OrderNewLineCredit',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [orders, ClientEdit],
    props: {
      client: Object,
      state: String,
      parameterPickupDeliveryType1: String,
      parameterPickupDeliveryType2: String,
      parameterModePaiement: String,
    },
    data () {
      return {
        language: this.$i18n.t('Language'),
        submitForm: {
          paiement_automatique: null,
        },
        messageConfirmation: null,
        provider: [],
        itemsContratType: [this.$i18n.t('order.Credit_card')],
        pickupComptoir: [],
        parameterPickupDelivery: null,
        clientSearch: {
          client: {},
          entries: [],
          isLoading: false,
          search: null,
        },
      }
    },

    computed: {
      ...mapGetters('parameters', {
        parameterFournisseur: 'getFournisseur',
        parameterCommanderPour: 'getCommanderPour',
      }),
    },

    watch: {
      // On regarde le type
      'submitForm.type' (type) {
        if (type === 1) this.parameterPickupDelivery = this.parameterPickupDeliveryType1
        if (type === 2) this.parameterPickupDelivery = this.parameterPickupDeliveryType2

        // Définir le champs livraison d'après le type
        this.setPickupDelivery()
      },
    },

    methods: {
      changeType (type) {
        if (type === 1) {
          // Reset migration
          this.submitForm.no_telephone = null
          this.submitForm.migration_fournisseur = null
          this.submitForm.migration_no_contrat = null
          this.submitForm.migration_responsable = null
          this.submitForm.migration_adresse = null
        } else if (type === 2) {
          this.submitForm.livraison = false
        }
      },
      changePaymentMode (value) {
        if (this.client.Inscription_paiements.length === 1) {
          this.submitForm.InscriptionPaiementId = this.client.Inscription_paiements[0].id
        }
      },
      async submit () {
        // Start Spinner
        this.$loading.show()

        // On mets le id du client pour qui est la commande si on commande pour une autre personne
        if (this.clientSearch.client.id) this.submitForm.ClientId = this.clientSearch.client.id

        // Pour les migrations
        if (this.submitForm.type === 2) {
          this.submitForm.migration_type = 3
          if (this.submitForm.migration_fournisseur.toLowerCase() === 'telus') this.submitForm.migration_type = 1
          if (this.submitForm.migration_fournisseur.toLowerCase() === 'pc mobile' || this.submitForm.migration_fournisseur.toLowerCase() === 'public mobile' || this.submitForm.migration_fournisseur.toLowerCase() === 'koodo') this.submitForm.migration_type = 2
        }
        // Paiement automatique (Si le paiement automatique est false on ne mets pas le id de carte)
        if (!this.submitForm.paiement_automatique) this.submitForm.InscriptionPaiementId = null

        if (this.state === 'create') {
          // Par qui la commande à été faites
          this.submitForm.ClientIdPar = this.client.id

          this.submitForm.ClientId = this.client.id
          this.submitForm.mode_paiement = 2

          // Message
          const message = this.messageConfirmation[this.language]

          // On soumets la commande. Voir le composant 'components/Client/Order/demoUqam/Order.vue'
          this.$emit('submit', this.submitForm, message)
        } else {
          // On soumets la commande. Voir le composant '/components/Client/Order/MyOrders.vue'
          this.$emit('submit', this.submitForm)
        }
      },
      async resetData () {
        // Start Spinner
        this.$loading.show()
        try {
          // On récupère les fournisseur
          ListService.search('commandes_fournisseur').then(response => {
            this.provider = []
            response.forEach(currentItem => {
              this.provider.push({ item: currentItem.item, valeur: currentItem[this.language] })
            })
          })

          // On récupère des comptoirs
          this.pickupComptoir = await ListService.search('commandes_comptoir')

          // Reset des infos si c'est une nouvelle commande
          if (this.state === 'create') this.resetSubmitForm(null)

          // On récupère le message de confirmation
          MessagesService.search(13).then(response => {
            this.messageConfirmation = response
          })

          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
