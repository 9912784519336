<template>
  <v-container
    id="regular-forms"
    fluid
    tag="section"
  >
    <validation-observer
      ref="obs"
      v-slot="{ invalid, handleSubmit }"
    >
      <form>
        <!--  Administrateur-->
        <v-row
          v-if="ifRoleAdminAndRouteOrder"
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="success"
              icon="mdi-lock-open"
              :title="$t('Administrator')"
              class="px-5 py-3 mt-3"
            >
              <!-- Type de commande-->
              <v-subheader class="display-1 mt-3">
                {{ $t('Status') }}
              </v-subheader>

              <validation-provider
                v-slot="{ errors, valid }"
                :name="$t('Status')"
                rules="required"
              >
                <v-select
                  v-model="submitForm.statut"
                  :disabled="true"
                  class="ml-4 mt-0"
                  :items="statusAdmin"
                  item-text="item"
                  item-value="valeur"
                  persistent-hint
                  single-line
                  :error-messages="errors"
                  :success="valid"
                  required
                />
              </validation-provider>

              <!-- No de commande du fournisseur-->
              <v-subheader class="display-1 mt-3">
                No CMD {{ parameterFournisseur.toUpperCase() }}
              </v-subheader>

              <v-text-field
                v-model="submitForm.fournisseur_no_commande"
                :disabled="state === 'view'"
                class="ml-4 mt-0"
              />
            </base-material-card>
          </v-col>
        </v-row>

        <!-- Pour qui la commande (Pour la route est 'operation-commande' ou si le client est admin dans la table du client)-->
        <v-row
          v-if="ifCommanderPour"
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="success"
              icon="mdi-account"
              :title="$t('order.Order_for')"
              class="px-5 py-3 mt-3"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Client"
                rules="required"
              >
                <v-autocomplete
                  v-model="clientSearch.client"
                  class="ml-4 mt-0"
                  :items="clientSearch.entries"
                  :loading="clientSearch.isLoading"
                  :search-input.sync="clientSearch.search"
                  hide-no-data
                  hide-selected
                  item-text="firstNameName"
                  item-value="id"
                  :placeholder="$t('Start_typing_Search')"
                  append-icon="mdi-account"
                  return-object
                  :error-messages="errors"
                  :success="valid"
                  required
                  @keypress.enter="$event.preventDefault()"
                >
                  <template #selection="data">
                    {{ data.item.firstNameName }}
                  </template>
                  <template #item="data">
                    {{ data.item.firstNameName }}
                  </template>
                </v-autocomplete>
              </validation-provider>
            </base-material-card>
          </v-col>
        </v-row>

        <!--  Statut et Type-->
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="success"
              icon="mdi-book-information-variant"
              :title="$t('order.Status_type')"
              class="px-5 py-3 mt-3"
            >
              <!-- Type de commande-->
              <v-subheader class="display-1 mt-3">
                {{ $t('order.Order_type') }}
              </v-subheader>

              <validation-provider
                v-slot="{ errors, valid }"
                :name="$t('order.Order_type')"
                rules="required"
              >
                <v-radio-group
                  v-model="submitForm.type"
                  :disabled="state === 'view'"
                  row
                  class="ml-4 mt-0"
                  :error-messages="errors"
                  :success="valid"
                  required
                  @change="changeType($event)"
                >
                  <v-radio
                    :label="$t('order.Activation_new_number')"
                    :value="1"
                  />

                  <v-radio
                    :label="$t('order.transfer_number')"
                    :value="2"
                  />
                </v-radio-group>
              </validation-provider>

              <!-- Si c'est un transfert de ligne-->
              <template v-if="submitForm.type === 2">
                <div class="mt-5">
                  <v-subheader class="display-1 mt-3">
                    {{ $t('order.message_migration') }}
                  </v-subheader>
                </div>

                <!-- Numéro à migrer-->
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('order.Phone_number_to_migrate')"
                  :rules="ifNoTelephoneExist"
                >
                  <v-text-field
                    v-model="submitForm.no_telephone"
                    :disabled="state === 'view'"
                    maxlength="10"
                    placeholder="xxxxxxxxxx"
                    class="ml-4 mt-0"
                    :label="$t('order.Phone_number_to_migrate')"
                    rows="1"
                    auto-grow
                    append-icon="mdi-phone"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>

                <!-- Fournisseur actuel-->
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('contract.Provider')"
                  rules="required"
                >
                  <v-select
                    v-model="submitForm.migration_fournisseur"
                    :disabled="state === 'view'"
                    class="ml-4 mt-0"
                    :label="$t('order.Current_provider')"
                    :items="provider"
                    item-text="item"
                    item-value="valeur"
                    persistent-hint
                    single-line
                    :error-messages="errors"
                    :success="valid"
                    required
                    @change="changeFournisseur"
                  />
                </validation-provider>

                <!-- Numéro à temporaire-->
                <!-- Seulement si la route est 'operation-commande' (operation) et pour les fournisseur spécial (Pc Mobile etc...)-->
                <validation-provider
                  v-if="ifRouteOrder && specialProvider"
                  v-slot="{ errors, valid }"
                  :name="$t('order.Temporary_phone_number')"
                  :rules="{ required:false, min:10, max:10, numeric:true, numberPhoneIsDuplicates: submitForm.no_telephone }"
                >
                  <v-text-field
                    v-model="submitForm.no_telephone_temporaire"
                    type="number"
                    placeholder="xxxxxxxxxx"
                    class="ml-4 mt-0"
                    :label="$t('order.Temporary_phone_number')"
                    rows="1"
                    auto-grow
                    append-icon="mdi-phone"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>

                <!-- Numéro du compte-->
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('order.Account_number')"
                  rules="required"
                >
                  <v-text-field
                    v-model="submitForm.migration_no_contrat"
                    :disabled="state === 'view'"
                    class="ml-4 mt-0"
                    :label="$t('order.Account_number')"
                    append-icon="mdi-card-account-details"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>

                <!-- Nom du responsable du compte-->
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('order.Name_account')"
                  rules="required"
                >
                  <v-text-field
                    v-model="submitForm.migration_responsable"
                    :disabled="state === 'view'"
                    class="ml-4 mt-0"
                    :label="$t('order.Name_account')"
                    append-icon="mdi-account"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>

                <!-- Adresse du responsable du compte-->
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('order.Address_account')"
                  rules="required"
                >
                  <v-textarea
                    v-model="submitForm.migration_adresse"
                    :disabled="state === 'view'"
                    class="ml-4 mt-0"
                    :label="$t('order.Address_account')"
                    auto-grow
                    append-icon="mdi-comment"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </template>

              <!-- Type de forfait-->
              <v-subheader class="display-1 mt-3">
                {{ $t('order.Package_type') }}
              </v-subheader>

              <validation-provider
                v-slot="{ errors, valid }"
                :name="$t('order.Package_type')"
                rules="required"
              >
                <v-radio-group
                  v-model="submitForm.forfait"
                  :disabled="state === 'view'"
                  row
                  class="ml-4 mt-0"
                  :error-messages="errors"
                  :success="valid"
                  required
                >
                  <v-radio
                    :label="$t('order.Voice_only')"
                    :value="1"
                  />

                  <v-radio
                    :label="$t('order.Data_only')"
                    :value="2"
                  />

                  <v-radio
                    :label="$t('order.Voice_data')"
                    :value="3"
                  />
                </v-radio-group>
              </validation-provider>

              <!-- Description du contrat-->
              <v-subheader class="display-1 mt-3">
                {{ $t('order.User_use_number') }}
              </v-subheader>

              <validation-provider
                v-slot="{ errors, valid }"
                :name="$t('order.User_use_number')"
                rules="required"
              >
                <v-text-field
                  v-model="submitForm.contrat_label"
                  :disabled="state === 'view'"
                  class="ml-4 mt-0"
                  append-icon="mdi-account"
                  :error-messages="errors"
                  :success="valid"
                  required
                />
              </validation-provider>
            </base-material-card>
          </v-col>
        </v-row>

        <!-- LIVRAISON/CUILLETTE-->
        <v-row
          v-if="showPickupDelivery"
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="green"
              icon="mdi-truck-fast"
              :title="$t('order.Pickup_Delivery')"
              class="px-5 py-3 mt-3"
            >
              <!-- On affiche le choix si il y a une cueillette et une livraison-->
              <validation-provider
                v-if="parameterPickupDelivery === '0'"
                v-slot="{ errors, valid }"
                :name="$t('order.Pickup_Delivery')"
                rules="required"
              >
                <v-radio-group
                  v-model="submitForm.livraison"
                  :disabled="state === 'view'"
                  row
                  class="ml-4 mt-3"
                  :error-messages="errors"
                  :success="valid"
                  required
                >
                  <!-- Cueillette-->
                  <v-radio
                    :label="$t('order.Pickup')"
                    :value="false"
                  />

                  <!-- Livraison-->
                  <v-radio
                    :label="$t('order.Delivery')"
                    :value="true"
                  />
                </v-radio-group>
              </validation-provider>

              <template v-if="submitForm.livraison !== null">
                <!-- Adresse pour la livraison-->
                <template v-if="submitForm.livraison">
                  <v-subheader class="display-1 mt-3">
                    {{ $t('order.Delivery') }}
                  </v-subheader>

                  <!-- Numéro de suivi-->
                  <!-- Pour les Opérations seulement-->
                  <v-text-field
                    v-if="ifRouteOrder"
                    v-model="submitForm.livraison_no_suivi"
                    :disabled="state === 'view'"
                    class="ml-4 mt-0"
                    :label="$t('order.Tracking_number')"
                  />

                  <!-- Nom-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('Name')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="submitForm.livraison_adresse.nom"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :label="$t('Name')"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>

                  <!-- Prénom-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('FirstName')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="submitForm.livraison_adresse.prenom"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :label="$t('FirstName')"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>

                  <!-- Adresse-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('client.Address')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="submitForm.livraison_adresse.adresse"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :label="$t('client.Address')"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>

                  <!-- Ville-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('client.City')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="submitForm.livraison_adresse.ville"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :label="$t('client.City')"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>

                  <!-- Province-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('client.City')"
                    rules="required"
                  >
                    <v-select
                      v-model="submitForm.livraison_adresse.province"
                      label="Province"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :items="provinces"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>

                  <!-- Code Postale-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('client.ZIP_code')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="submitForm.livraison_adresse.code_postal"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :label="$t('client.ZIP_code')"
                      :error-messages="errors"
                      :success="valid"
                      placeholder="H0H 0H0"
                      required
                    />
                  </validation-provider>
                </template>

                <!-- Cueillette-->
                <template v-else>
                  <v-subheader class="display-1 mt-3">
                    {{ $t('order.Pickup') }}
                  </v-subheader>

                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('Counter')"
                    rules="required"
                  >
                    <v-list
                      shaped
                    >
                      <v-list-item-group
                        v-model="submitForm.ComptoirId"
                        class="ml-4 mt-0"
                        :error-messages="errors"
                        :success="valid"
                        required
                      >
                        <template v-for="(item, i) in getComptoirList">
                          <v-divider
                            v-if="!item"
                            :key="`divider-${i}`"
                          />

                          <v-list-item
                            v-else
                            :key="`item-${i}`"
                            :disabled="state === 'view' || item.id === submitForm.ComptoirId || getComptoirList.length === 1"
                            :value="item.id"
                            active-class="green--text text--accent-4"
                          >
                            <template #default="{ active }">
                              <v-list-item-action>
                                <v-checkbox
                                  :input-value="active"
                                  color="green"
                                />
                              </v-list-item-action>

                              <v-list-item-content>
                                <v-list-item-title v-html="item[language]" />
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </validation-provider>
                </template>
              </template>
            </base-material-card>
          </v-col>
        </v-row>

        <!-- FACTURATION-->
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="success"
              icon="mdi-currency-usd"
              :title="$t('order.Billing_code')"
              class="px-5 py-3 mt-3"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="UBR"
                :rules="getUbrValidationRules"
              >
                <v-text-field
                  v-model="submitForm.code_facturation"
                  :disabled="state === 'view'"
                  class="mt-3"
                  :maxlength="parameterLongueurCodeFacturation"
                  :label="$t('order.ubr_description')"
                  :error-messages="errors"
                  :success="valid"
                  required
                />

                <!-- Responsable de l'UBR-->
                <div v-if="ubrResponsableMessage">
                  <v-icon color="success">
                    mdi-check
                  </v-icon>
                  <span class="success--text">{{ ubrResponsableMessage }}</span>
                </div>
              </validation-provider>

              <!-- Validation UBR-->
              <template v-if="ubrValidation">
                <div class="mt-5">
                  <v-icon color="orange">
                    mdi-alert
                  </v-icon>
                  <span class="red--text ml-1">{{ ubrValidationMessage === '' ? 'ATTENTION' : 'ATTENTION:' }}</span>

                  <p class="text-justify red--text">
                    {{ ubrValidationMessage }}
                  </p>
                </div>

                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('order.validation.Billing_confirmation')"
                  rules="required"
                >
                  <v-checkbox
                    v-if="ubrValidationConfirmation && validationUBRCheckbox"
                    v-model="submitForm.code_facturation_confirmation"
                    :disabled="state === 'view'"
                    class="mt-0"
                    :value="true"
                    :error-messages="errors"
                    :success="valid"
                    required
                  >
                    <span
                      slot="label"
                      class="red--text"
                    >{{ $t('order.validation.condition_accepted') }}</span>
                  </v-checkbox>
                </validation-provider>
              </template>
            </base-material-card>
          </v-col>
        </v-row>

        <!-- COMMENTAIRES-->
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="success"
              icon="mdi-comment"
              :title="$t('Comment')"
              class="px-5 py-3 mt-3"
            >
              <v-textarea
                v-model="submitForm.note"
                :disabled="state === 'view'"
                class="ml-4 mt-3"
                :label="$t('order.comment_description')"
                rows="1"
                auto-grow
                append-icon="mdi-comment"
              />
            </base-material-card>
          </v-col>
        </v-row>

        <!-- CONFIRMATION-->
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              v-if="state !== 'view'"
              :disabled="invalid"
              class="mt-3"
              color="success"
              @click="handleSubmit(submit)"
            >
              {{ $t('Validate') }}
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
  // Services
  import ListService from '@/services/01Cell/ListService'
  import CommandesService from '@/services/01Cell/CommandesService'
  import MessagesService from '@/services/01Cell/MessagesService'
  // Mixins
  import { orders } from '@/mixins/Client/Order/orders'
  import { ClientEdit } from '@/mixins/Client/ClientEdit'
  import { ordersUbrRules } from '@/mixins/Client/Order/ordersUbrRules'
  // Other
  import { mapGetters } from 'vuex'
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'

  export default {
    name: 'OrderNewLineUbr',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [orders, ClientEdit, ordersUbrRules],
    props: {
      client: Object,
      state: String,
      parameterPickupDeliveryType1: String,
      parameterPickupDeliveryType2: String,
      parameterLongueurCodeFacturation: String,
    },
    data () {
      return {
        language: this.$i18n.t('Language'),
        submitForm: {},
        messageConfirmation: null,
        ubrResponsableMessage: null,
        ubrValidation: false,
        ubrValidationConfirmation: false,
        ubrValidationMessage: '',
        provider: [],
        pickupComptoir: [],
        parameterPickupDelivery: null,
        compteUQAM: '57012',
        clientSearch: {
          client: {},
          entries: [],
          isLoading: false,
          search: null,
        },
      }
    },

    computed: {
      ...mapGetters('parameters', {
        parameterFournisseur: 'getFournisseur',
        parameterCommanderPour: 'getCommanderPour',
      }),
      validationUBRCheckbox () {
        // Si il y a des erreurs dans la validation on affiche pas le bouton
        if (this.$refs.obs.errors.UBR) {
          if (this.$refs.obs.errors.UBR.length) return false
        }
        return true
      },
    },

    watch: {
      // On regarde le type
      'submitForm.type' (type) {
        // Nouvelle ligne
        if (type === 1) this.parameterPickupDelivery = this.parameterPickupDeliveryType1
        // Migration de ligne
        if (type === 2) this.parameterPickupDelivery = this.parameterPickupDeliveryType2

        // Définir le champs livraison d'après le type
        this.setPickupDelivery()
      },
      // Validation du UBR
      'submitForm.code_facturation' (val) {
        if (!val) return

        // L'UQAM, On récupère le responsable de l'UBR
        if (this.$company === 'uqam') {
          this.ubrResponsableMessage = null
          if (val.length === 6 && val !== '000000') {
            // On regarde si la val est un chiffre
            if (!isNaN(val)) {
              CommandesService.responsableCodeFacturation(val).then(response => {
                if (response) {
                  this.ubrResponsableMessage = `${this.$i18n.t('order.validation.valid_ubr')} (${response})`
                } else {
                  this.ubrValidation = true
                  this.ubrValidationMessage = ''
                }
              })
            }
          }

          if (val.startsWith('3') || val.startsWith('000000')) {
            this.ubrValidation = true
            this.ubrValidationConfirmation = true
            if (val.startsWith('37') || val.startsWith('35') || val.startsWith('000000')) {
              this.ubrValidationMessage = this.$i18n.t('order.validation.ubr3537')
              // Pour l'utilisateur qui commande, on mets le UBR '000000'. Si c'est un admin il peut mettre ce qu'il veux
              if (this.$route.name === 'Dashboard') this.submitForm.code_facturation = '000000'
            } else {
              this.ubrValidationMessage = this.$i18n.t('order.validation.ubr3or7')
            }
            return
          }

          if (val.startsWith('7')) {
            this.ubrValidation = true
            this.ubrValidationConfirmation = true
            this.ubrValidationMessage = this.$i18n.t('order.validation.ubr3or7')
            return
          }
          this.ubrValidation = false
        }
      },
    },

    methods: {
      changeType (type) {
        if (type === 1) {
          // Reset migration
          this.submitForm.no_telephone = null
          this.submitForm.migration_fournisseur = null
          this.submitForm.migration_no_contrat = null
          this.submitForm.migration_responsable = null
          this.submitForm.migration_adresse = null
        } else if (type === 2) {
          this.submitForm.livraison = false
        }
      },
      changeFournisseur (event) {
        // Si c'est une migration Telus, il n'y a pas de livraison
        if (event === 'Telus') {
          this.submitForm.livraison = false
        }
      },
      async submit () {
        // Start Spinner
        this.$loading.show()

        // On mets le id du client pour qui est la commande si on commande pour une autre personne
        if (this.clientSearch.client.id) this.submitForm.ClientId = this.clientSearch.client.id

        // Pour les migrations
        if (this.submitForm.type === 2) {
          this.submitForm.migration_type = 3
          if (this.submitForm.migration_fournisseur.toLowerCase() === 'telus') this.submitForm.migration_type = 1
          if (this.submitForm.migration_fournisseur.toLowerCase() === 'pc mobile' || this.submitForm.migration_fournisseur.toLowerCase() === 'public mobile' || this.submitForm.migration_fournisseur.toLowerCase() === 'koodo') this.submitForm.migration_type = 2
        }

        if (this.state === 'create') {
          this.submitForm.mode_paiement = 1
          // Par qui la commande à été faites
          this.submitForm.ClientIdPar = this.client.id
          // Message
          // On soumets la commande. Voir le composant 'components/Client/Order/demoUqam/Order.vue'
          this.$emit('submit', this.submitForm, this.messageConfirmation[this.language])
        } else {
          // On soumets la commande. Voir le composant '/components/Client/Order/MyOrders.vue'
          this.$emit('submit', this.submitForm)
        }
      },
      async resetData () {
        // Start Spinner
        this.$loading.show()
        try {
          ListService.search('commandes_fournisseur').then(response => {
            this.provider = []
            response.forEach(currentItem => {
              this.provider.push({ item: currentItem.item, valeur: currentItem[this.language] })
            })
          })

          // On récupère des comptoirs
          this.pickupComptoir = await ListService.search('commandes_comptoir')

          // Reset des infos si c'est une nouvelle commande
          if (this.state === 'create') this.resetSubmitForm(null)

          // On récupère le message de confirmation
          MessagesService.search(10).then(response => {
            this.messageConfirmation = response
          })

          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
